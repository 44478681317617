import { Injectable } from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { D3HorizontalTreeData, D3HorizontalTreeDataMapper } from '@app/@shared/model/d3/horizontal-tree.model';
import { Infrastructure } from '@app/@shared/model/infrastructure.model';
import { catchError, map, Observable, throwError } from 'rxjs';
import { MainService } from '../main.service';

import { PlatformService } from '@app/auth/platform.service';
import { plainToClass } from 'class-transformer';
import { IQueryViewConfiguration } from '@app/@shared/model/interface/iQuery-view-configuration.model';
import { vOdfBox } from '@app/@shared/model/aux-models/vOdfBox';

@Injectable()
export class InfrastructureService {
  constructor(
    private main: MainService,
    private platformService: PlatformService,
  ) {}

  getWithFilter(entity?: any, limit? : number, offset?: number, clause? : string): Observable<any> {
    this.main.chooseAPI(Cons._INFRASTRUCTURE);
    return this.main.getWithFilter('Infrastructure', entity, true, limit, offset, clause).pipe(
      map((res) => {
        try {
          return res.responseData.map((x) => {
            x.commonID = x.infrastructureID;
            x.entityName = Cons._INFRASTRUCTURE;
            return <Infrastructure>x;
          });
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

  queryView(entity: any, xyCoordinates?: any[], locationCriteria?, networkType? : string): Observable<any> {
    this.main.chooseAPI(Cons._INFRASTRUCTURE);

    const queryViewConfiguration: IQueryViewConfiguration = {
      xyCoordinates,
      locationCriteria,
    };

    return this.main
      .queryView('Infrastructure', entity, queryViewConfiguration)
      .pipe(
        map((res) => {
          try {
            return res.responseData.filter(x => networkType ? x.infrastructureAttributes?.poleno !== undefined ? x.infrastructureAttributes.poleno.replace(/[^a-zA-Z]/g, '').toUpperCase().startsWith(networkType) : false : true).map((x) => {
              x.commonID = x.infrastructureID;
              x.entityName = Cons._INFRASTRUCTURE;
              networkType ? x.infrastructureAttributes.poleno.startsWith(networkType) : null;
              return <Infrastructure>x;
            });
          } catch (ex) {
            return <string>(<any>res)._body.toString();
          }
        })
      );
  }

  getByID(id: any): Observable<any> {
    this.main.chooseAPI('INFRASTRUCTURE');
    return this.main.getByID('Infrastructure', id).pipe(
      map((res) => {
        try {
          res.commonID = res.infrastructureID;
          res.entityName = 'INFRASTRUCTURE';
          const infrastructure: Infrastructure = plainToClass(Infrastructure, res);
          return infrastructure;
        } catch (ex) {
          return <string>(<any>res)._body.toString();
        }
      })
    );
  }

 
  

  getODFByMslink(mslink: number): Observable<D3HorizontalTreeData[]> {
    const platformID = this.platformService.platformID;
    this.main.chooseAPI(Cons._INFRASTRUCTURE);

    return this.main.get('DIAGRAMCONNECTIONS/ByMslink', `${platformID}/${mslink}`).pipe(
      map((x: any) => {
        return D3HorizontalTreeDataMapper.Map(x.responseData);
      }),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }

  getODF(infrastructureID: number): Observable<D3HorizontalTreeData[]> {
    const platformID = this.platformService.platformID;
    this.main.chooseAPI(Cons._INFRASTRUCTURE);

    return this.main.get('DIAGRAMCONNECTIONS', `${platformID}/${infrastructureID}`).pipe(
      map((x: any) => {
        return D3HorizontalTreeDataMapper.Map(x.responseData);
      }),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
  update(infrastructure: Infrastructure): Observable<any> {
    this.main.chooseAPI(Cons._INFRASTRUCTURE);
    return this.main.put(`Infrastructure/${infrastructure.platformID}/${infrastructure.infrastructureID}`, undefined, infrastructure).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
  create(infrastructure: Infrastructure): Observable<any> {
    this.main.chooseAPI(Cons._INFRASTRUCTURE);
    return this.main.post(Cons._INFRASTRUCTURE, undefined, infrastructure).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }

  delete(infrastructureID: number): Observable<any> {
    this.main.chooseAPI(Cons._INFRASTRUCTURE);
    return this.main.delete(Cons._INFRASTRUCTURE, infrastructureID).pipe(
      map((x) => x.responseData),
      catchError((ex) => throwError(() => new Error(ex)))
    );
  }
}
