import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MapSourceService } from '@app/@shared/services/map-elements-services/map-source.service';
import { PlatformService } from '@app/auth/platform.service';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';
import { filter, firstValueFrom, tap } from 'rxjs';
import WKT from 'ol/format/WKT';
import { ChangeDetectorRef } from '@angular/core';
import { LocationData } from '@app/@shared/model/aux-models/locationData';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { ShapeService } from '@app/@shared/services/shape.service';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import CustomStore from 'devextreme/data/custom_store';
import { Cons } from '@app/@shared/cons/cons';
import { ActivatedRoute } from '@angular/router';
import { ProjectQueryViewResponse } from '@app/@shared/model/interface/shapeDataInterface';
import { TranslateService } from '@ngx-translate/core';
import { olMapPoint } from '@app/@shared/model/aux-models/ol-map-models/olMapPoint';
import { UserSearch } from '@app/@shared/model/aux-models/user-search.model';
import cloneDeep from 'lodash.clonedeep';
import { ShapeFeatureInterface } from '@app/@shared/model/interface/shape-feature.interface';

@Component({
  selector: 'app-constructive-feasibility-map',
  templateUrl: './constructive-feasibility-map.component.html',
  styleUrls: ['./constructive-feasibility-map.component.scss']
})
export class ConstructiveFeasibilityMapComponent implements OnInit, AfterViewInit {

  @ViewChild('olMap') olMap: OlMapComponent;

  latitude: number = -34.604369;
  longitude: number = -58.3850703;
  zoom: number = 8;
  drawerOpened: boolean = false;
  showOverflow: boolean = true;
  isSearchOpen = true;
  mapButtonClicked = false;
  dowloadImg = false;
  eraserActive = false;
  shareIcon = "../../../../../../../assets/icons/sharedViewIcon.png";
  traceIcon = "../../../../../../../assets/icons/sitemap-icon.png";
  selectedOption: any;
  accordion_search_selected = [];
  map: any;
  selectedMapOption: string;
  xygoMapSelected: boolean = false;
  googleMapSelected: boolean;
  openStreetMapSelected: boolean;
  points: any[] = [];
  lineStrings: any[] = [];
  multiLineStrings: any[] = [];
  _points: any[] = [];
  _lineStrings: any[] = [];
  _multiLineStrings: any[] = [];
  element_datasource: CustomStore;
  selectedProject: ProjectQueryViewResponse = null;
  loading: boolean = false;
  isAccordionEnabled: boolean = false;
  checkboxOptions = [];
  subscription: any;
  shapeID: number;
  accordion_checkbox_selected = [];
  idCounter = 0;
  enableClusteredFeatures: boolean = false;
  shapesSearchedByUser: any[] = [];
  selectedShape: any;
  versionOptionsSelectedGroupedByProjectType: any[];


  ds_accordion_search_title = [
    { title: this.trans.instant('constructive-feasibility-search-panel.title') }
  ];

  ds_accordion_checkbox_title = [
    { title: this.trans.instant('constructive-feasibility-version-option.title') }
  ];

  mapOptions = [{ name: 'Xygo' }, { name: 'Google' }, { name: 'Open Street' }];


  constructor(
    private route: ActivatedRoute,
    private mapSourceService: MapSourceService,
    private platformService: PlatformService,
    private shapeService: ShapeService,
    private cdr: ChangeDetectorRef,
    private dataSourceService: DataSourceService,
    private trans: TranslateService

  ) { }

  async ngOnInit(): Promise<void> {

    this.element_datasource = this.dataSourceService.projectsDatasource;
    this.selectedMapOption = this.mapOptions[0].name;
    let initialPos = this.platformService.getPlatformPreference("initialPosition");
    if (initialPos) {
      this.latitude = initialPos.lat;
      this.longitude = initialPos.lon;
      this.zoom = initialPos.zoom;
    }
    if (initialPos) {
      this.latitude = initialPos.lat;
      this.longitude = initialPos.lon;
      this.zoom = initialPos.zoom;
    }
    else {
      this.getUserPosition().then(({ lon, lat, zoom }) => {
        this.latitude = lat;
        this.longitude = lon;
        this.zoom = zoom;
      },
        ((err) => {
          alert(err);
          this.latitude = -34;
          this.longitude = -58;
          this.zoom = 6;
        }));
    }

    const shapeIDString = this.route.snapshot.paramMap.get('shapeID');
    if (shapeIDString) {
      this.shapeID = +shapeIDString;
    }
  }

  async ngAfterViewInit(): Promise<void> {

    if (this.olMap && this.shapeID) {
      this.loading = true;
      let processedLinesAndPoints: any[] = [];
      let groupedLinesAndPoints;

      const elements = await this.getShapeFeaturesByShapeID(this.shapeID);

      for (const element of elements) {
        const processed = await this.processLinesAndPoints(element);
        if (processed !== null) {
            processedLinesAndPoints.push(processed);
        }
    }

    processedLinesAndPoints = processedLinesAndPoints.filter(item => item !== null);

    groupedLinesAndPoints = await this.categorizeFeatures(processedLinesAndPoints);

      await this.drawLinesAndPointsOnMap(processedLinesAndPoints);
      await this.goToPointPosition(groupedLinesAndPoints.points[0]);

    } else {
      this.loading = false;
    }
  }

  toggleDrawer() {
    this.drawerOpened = !this.drawerOpened;
  }

  onMapReady(map: any) {
    this.map = map;
  }

  downloadMap(): void {
    const size = this.map.getSize();
    const pixelRatio = window.devicePixelRatio || 1;
    const mapCanvas = this.createMapCanvas(size, pixelRatio);

    const mapContext = mapCanvas.getContext('2d');
    if (!mapContext) {
      console.error('Could not get the canvas context.');
      return;
    }

    mapContext.scale(pixelRatio, pixelRatio);

    this.renderMapLayersToCanvas(mapContext, mapCanvas);

    this.map.once('rendercomplete', () => {
      this.downloadCanvasAsImage(mapCanvas);
    });

    this.map.renderSync();
  }

  createMapCanvas(size: [number, number], pixelRatio: number): HTMLCanvasElement {
    const mapCanvas = document.createElement('canvas');
    mapCanvas.width = size[0] * pixelRatio;
    mapCanvas.height = size[1] * pixelRatio;
    mapCanvas.style.width = `${size[0]}px`;
    mapCanvas.style.height = `${size[1]}px`;
    return mapCanvas;
  }

  renderMapLayersToCanvas(mapContext: CanvasRenderingContext2D, mapCanvas: HTMLCanvasElement): void {
    const mapLayers = document.querySelectorAll('.ol-layer canvas');
    mapLayers.forEach((canvas) => {
      if (canvas instanceof HTMLCanvasElement && canvas.width > 0) {
        const transform = canvas.style.transform;
        const matrix = transform
          .match(/^matrix\(([^\)]+)\)$/)?.[1]
          .split(',')
          .map(Number);

        if (matrix) {
          mapContext.setTransform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
        } else {
          mapContext.setTransform(1, 0, 0, 1, 0, 0);
        }

        mapContext.drawImage(canvas, 0, 0);
      }
    });
  }

  downloadCanvasAsImage(mapCanvas: HTMLCanvasElement): void {
    mapCanvas.toBlob((blob) => {
      if (blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'map.png';
        link.click();
        URL.revokeObjectURL(link.href);
      }
    }, 'image/png');
  }

  selectMapOption(event: any, optionName: string) {
    if (optionName == Cons._XYGOTYPE) {
      this.xygoMapSelected = true;
      this.googleMapSelected = false;
      this.openStreetMapSelected = false;

    } else if (optionName == Cons._GOOGLETYPE) {
      this.xygoMapSelected = false;
      this.googleMapSelected = true;
      this.openStreetMapSelected = false;

    } else if (optionName == Cons._OPENSTREETTYPE) {
      this.xygoMapSelected = false;
      this.googleMapSelected = false;
      this.openStreetMapSelected = true;
    }
  }

  selectMap() {
    this.selectedMapOption = this.mapOptions[this.mapSourceService.mapSource].name;
    this.mapButtonClicked = !this.mapButtonClicked;
  }

  getUserPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp =>
        resolve({ lon: resp.coords.longitude, lat: resp.coords.latitude, zoom: 8 }),
        err => {
          //Geolocation didn't work. Set some harcoded position
          return resolve({ lon: -58.3850703, lat: -34.604369, zoom: 4 })
        }
      );
    });
  }


  async drawLinesAndPointsOnMap(elements: any[]) {
    try {

      const { points, lineStrings } = await this.categorizeFeatures(elements);

      this._points = points;
      this._lineStrings = lineStrings;

      console.log('this._points:', this._points);
      console.log('this_lineStrings:', this._lineStrings);

    } catch (error) {
      console.error('Error while drawing response:', error);
    } finally {
      this.loading = false;
    }
  }


  private async getShapeFeaturesByShapeID(shapeID: number): Promise<any[]> {
    const response = await firstValueFrom(this.shapeService.getByID(shapeID));
    return response.features;
  }

  private async categorizeFeatures(elements: any[]): Promise<{
    points: any[],
    lineStrings: any[],
    multiLineStrings: any[]
  }> {
    const points: any[] = [];
    const lineStrings: any[] = [];
    const multiLineStrings: any[] = [];

    for (const element of elements) {
      switch (element.type) {
        case Cons._POINTTYPE:
          points.push(element);
          break;
        case Cons._LINESTRINGTYPE:
          lineStrings.push(element);
          break;
        default:
          console.warn('Unsupported geometry type:', element.type);
          break;
      }
    }
    return { points, lineStrings, multiLineStrings };
  }

  private async processLinesAndPoints(response: any): Promise<{ type: string; element: any } | null> {
    try {
      let wkt;
      let coordinates;
      let flatCoordinates;
      let geometryType;
      let newElement: any;

      if (!response.element?.entity) {
        wkt = response?.geometry?.wkt;

        if (!wkt) {
          throw new Error('WKT is undefined');
        }

        coordinates = await this.readWktGeometry(wkt);
        flatCoordinates = coordinates?.flatCoordinates;
        if (!coordinates || !flatCoordinates) {
          throw new Error('Invalid coordinates from WKT');
        }

        geometryType = await this.getGeometryTypeFromWkt(wkt);
        if (!geometryType) {
          throw new Error('Invalid geometry type from WKT');
        }

        switch (geometryType) {
          case 'POINT':
            newElement = await this.createOlPoint(response, flatCoordinates);
            break;
          case 'LINESTRING':
            newElement = await this.createOlLineString(response, flatCoordinates);
            break;
          case 'MULTILINESTRING':
            newElement = await this.createOlMultiLineString(response, flatCoordinates);
            break;
          default:
            console.warn('Unsupported geometry type:', geometryType);
            return null;
        }
      } else {
        geometryType = response.type.toUpperCase();

        switch (geometryType) {
          case 'POINT':
          case 'LINESTRING':
          case 'MULTILINESTRING':
            newElement = response;
            break;
          default:
            console.warn('Unsupported geometry type:', geometryType);
            return null;
        }
      }

      return newElement;

    } catch (error) {
      console.error('Error processing response:', error);
      return null;
    }
  }

  async createOlPoint(point: ShapeFeatureInterface, coordinates: number[]): Promise<any> {



    let iconPath;
    const uniqueId = `point_${++this.idCounter}`;

    const pointEntity = {
      id: point.properties.id,
      name: point.properties.model || 'unknown_name',
      type: Cons._POINTTYPE,
      coordinatesEPSG4326: [...coordinates],
      entityName: point.properties.model,
      commonID: point.properties.id,
      elementTypeID: point.properties.elementType.elementTypeID,
      elementType: point.properties.elementType
    };

    if (point.properties.elementType && point.properties.elementType.filePath) {
      iconPath = `markers/${point.properties.elementType.filePath}`;
    } else {
      iconPath = 'markers/error.png';
    }

    return {
      id: uniqueId,
      color: 'rgb(0, 128, 255)',
      icon: iconPath,
      flatCoordinates: coordinates,
      visible: true,
      selected: false,
      name: point.properties.model || 'unknown_name',
      type: Cons._POINTTYPE,
      entity: pointEntity,
      popupEnable: true,
    };
  }

  async createOlLineString(lineString: ShapeFeatureInterface, coordinates: number[]): Promise<any> {
    const uniqueId = ++this.idCounter;
    const projectType = lineString.properties.shape.entityShape;
    const shapeVersion = lineString.properties.shape.version;

    const formattedCoordinates = [];
    for (let i = 0; i < coordinates.length; i += 2) {
      formattedCoordinates.push([coordinates[i], coordinates[i + 1]]);
    }

    const shapeID = lineString.properties.shapeID || (lineString.properties && lineString.properties.shapeID);
    const color = this.getColorByProjectTypeAndShapeVersion(shapeID, projectType, shapeVersion);

    const locationData = {
      inputSrID: 4326,
      outputSrID: 4326,
      coordinates: {
        geographyType: "LineString",
        values: `LINESTRING (${formattedCoordinates.map(coord => coord.join(' ')).join(', ')})`
      },
      format: "WKT"
    };

    const elementType = {
      specAttributes: {
        showFiber: true,
        bufferLength: 4,
        color: color,
        fiberData: []
      },
      elementTypeID: lineString.properties.elementType.elementTypeID,
      platformID: lineString.properties.elementType.platformID,
      name: lineString.properties.elementType.name,
      reference: lineString.properties.elementType.reference,
      groupID: lineString.properties.elementType.groupID,
      filePath: lineString.properties.elementType.filePath
    };

    const modelType = {
      specAttributes: {},
      elementTypeID: lineString.properties.elementType.elementTypeID,
      platformID: 0,
      name: lineString.properties.model,
      reference: lineString.properties.elementType.reference,
      groupID: lineString.properties.model,
      filePath: lineString.properties.elementType.filePath
    };

    const entity = {
      locationData: locationData,
      sectionAttributes: {},
      entitySignature: null,
      __modified: null,
      traceID: lineString.properties.id,
      platformID: 0,
      elementTypeID: lineString.properties.elementType.elementTypeID,
      modelTypeID: 0,
      containerID: 0,
      name: lineString.properties.model || "TEST",
      description: "",
      length: lineString.properties.length,
      sectionData: null,
      aud_User: lineString.properties.shape.userID,
      aud_Date: new Date().toISOString(),
      aud_Action: "",
      elementType: elementType,
      modelType: modelType,
      fiber: [],
      commonID: lineString.properties.id,
      entityName: lineString.properties.elementType.groupID
    };

    return {
      visible: true,
      selected: false,
      entity: entity,
      id: lineString.properties.id,
      name: lineString.properties.model || "TEST",
      type: Cons._LINESTRINGTYPE,
      color: color,
      width: 2,
      locationData: locationData,
      flatCoordinates: formattedCoordinates,
      elementTypeID: lineString.properties.elementType.elementTypeID,
    };
  }


  private createOlMultiLineString(multiLineString: any, coordinatesArray: number[][][]): any {

    const multiLineStringEntity = {
      id: multiLineString.id || 'unknown_id',
      name: multiLineString.name || 'unknown_name',
      type: 'MultiLineString',
      coordinatesEPSG4326: [...coordinatesArray],
      entityName: 'MultiLineString',
      commonID: multiLineString.id,
    };

    return {
      id: multiLineString.id || 'unknown_id',
      color: multiLineString.color || 'rgb(255, 69, 0)',
      width: 2,
      flatCoordinates: coordinatesArray,
      visible: true,
      selected: false,
      name: multiLineString.name || 'unknown_name',
      type: 'MultiLineString',
      entity: multiLineStringEntity,
      popupEnable: true,
    };
  }


  readWktGeometry(wkt: string): any {
    const wktFormat = new WKT();
    return wktFormat.readGeometry(wkt);
  }

  private getGeometryTypeFromWkt(wkt: string): string | null {
    const match = wkt.match(/^\s*(\w+)/);
    return match ? match[1].toUpperCase() : null;
  }

  private getColorByProjectTypeAndShapeVersion(shapeID: number, projectType: string, version: number): string {
    const baseColors: { [key: string]: string[] } = {
      [Cons._FACTIBILIDAD]: ['#FFD1D1', '#FF8080', '#FF3333', '#B20000'], // Red (Lightest to Darkest)
      [Cons._MUNICIPIO]: ['#D1D1FF', '#8080FF', '#3333FF', '#0000B2'], // Blue (Lightest to Darkest)
      [Cons._CAO]: ['#D1FFD1', '#80FF80', '#33CC33', '#008000'] // Green (Lightest to Darkest)
    };

    const isValidProjectType = baseColors.hasOwnProperty(projectType.toUpperCase());
    const baseColorArray = isValidProjectType ? baseColors[projectType.toUpperCase()] : ['#000000'];

    const darkerColor = baseColorArray[baseColorArray.length - 1];

    const findProjectMatch = this.versionOptionsSelectedGroupedByProjectType?.find(
      (searchedProject) => searchedProject?.entityShape === projectType
    );

    if (!findProjectMatch) {
      return '#000000';
    }

    const selectedVersions = findProjectMatch.selectedOptions.map(option => option.shapeID);
    const totalVersionsAvailable = selectedVersions.length;

    if (totalVersionsAvailable === 1) {
      return darkerColor;
    }

    selectedVersions.sort((a, b) => a - b);

    const relativeIndex = selectedVersions.indexOf(shapeID);

    const versionIndex = Math.max(0, baseColorArray.length - totalVersionsAvailable + relativeIndex);

    return baseColorArray[versionIndex];
  }



  async search() {
    this.loading = true;
    const projectID = this.selectedProject.projectID;
    const response = await firstValueFrom(this.shapeService.getAllShapesByProjectID(projectID));
    await this.transformSearchResponseToCheckBox(response);
    this.loading = false;
  }

  async drawShape() {
    let selectedOptions;
    let allLinesAndPoints: any[] = [];
    this.loading = true;
    let processedLinesAndPoints;
    let groupedLinesAndPoints;

    this.resetProperties();

    this.versionOptionsSelectedGroupedByProjectType = await this.getSelectedOptionsFromCheckBox();

    selectedOptions = this.versionOptionsSelectedGroupedByProjectType.flatMap(group =>
      group.selectedOptions.map(option => ({
        ...option,
        // Format display name: Example: 'M. Version1' (First letter of entityShape + label)
        name: `${group.entityShape.charAt(0)}. ${option.label}`
      }))
    );

    for (let i = 0; i < selectedOptions.length; i++) {
      const currentVersionSelected = selectedOptions[i];
      const shapeID = selectedOptions[i].shapeID;

      const linesAndPoints = await this.getShapeFeaturesByShapeID(shapeID);

      processedLinesAndPoints = await Promise.all(
        linesAndPoints.map(line => this.processLinesAndPoints(line))
      );

      processedLinesAndPoints = processedLinesAndPoints.filter(item => item !== null);

      groupedLinesAndPoints = await this.categorizeFeatures(processedLinesAndPoints);

      await this.addUserSearch(
        currentVersionSelected,
        groupedLinesAndPoints.points,
        groupedLinesAndPoints.lineStrings
      );

      allLinesAndPoints = allLinesAndPoints.concat(processedLinesAndPoints);
    }

    await this.drawLinesAndPointsOnMap(allLinesAndPoints);
    await this.goToPointPosition(groupedLinesAndPoints.points[0]);

    this.loading = false;
  }

  async getSelectedOptionsFromCheckBox(): Promise<any[]> {
    return this.checkboxOptions
      .filter(group => group.selected || group.subOptions.some(option => option.selected))
      .map(group => ({
        entityShape: group.label,
        selectedOptions: group.subOptions.filter(option => option.selected)
      }));
  }

  async transformSearchResponseToCheckBox(response: any): Promise<void> {
    const entityGroups: Record<string, any> = {};
  
    const order = [Cons._MUNICIPIO, Cons._FACTIBILIDAD, Cons._CAO].map(e => e.toUpperCase());
  
    for (const shape of response) {
      const shapeData = shape.shapeData;
      const entityShape = shapeData.entityShape;
      const normalizedEntityShape = entityShape.toUpperCase();
      const versionLabel = `Version ${shapeData.version}`;
      const shapeID = shapeData.shapeID;
  
      if (!entityGroups[normalizedEntityShape]) {
        entityGroups[normalizedEntityShape] = {
          label: entityShape,
          selected: false,
          subOptions: []
        };
      }
  
      entityGroups[normalizedEntityShape].subOptions.push({
        label: versionLabel,
        shapeID,
        selected: false
      });
    }
  
    this.checkboxOptions = Object.values(entityGroups).sort((a, b) => {
      const aIndex = order.indexOf(a.label.toUpperCase());
      const bIndex = order.indexOf(b.label.toUpperCase());
      return aIndex - bIndex;
    });
  
    this.isAccordionEnabled = true;
  }



  updateParentCheckbox(option: any) {
    const checkedCount = option.subOptions.filter((sub: any) => sub.selected).length;

    if (checkedCount > 0) {
      option.selected = true;
      option.indeterminate = checkedCount < option.subOptions.length;
    } else {
      option.selected = false;
      option.indeterminate = false;
    }
  }

  toggleAllSubOptions(option: any) {
    const isChecked = option.selected;

    option.subOptions.forEach((sub: any) => {
      sub.selected = isChecked;
    });
  }

  addUserSearch(response: any, drawnPoints?: olMapPoint[], drawnLineStrings?: olMapLineString[]): UserSearch {
    const shapeName: string = response?.name;
    const existingUserSearch = this.shapesSearchedByUser.find(x => x.name === shapeName);

    if (existingUserSearch) {
      existingUserSearch.visible = false;
      this.toggleShapeVisibility(existingUserSearch);
      return null;
    }

    const safePoints = drawnPoints || [];
    const safeLineStrings = drawnLineStrings || [];

    const userSearch: UserSearch = {
      name: shapeName,
      visible: true,
      mapFeatures: {
        lineStrings: safeLineStrings,
        points: safePoints,
      },
      response: cloneDeep([...safeLineStrings, ...safePoints])
    };

    const userSearchDeepCopy = cloneDeep(userSearch);


    this.shapesSearchedByUser.push(userSearchDeepCopy);
    this.selectedShape = userSearchDeepCopy;

    return userSearchDeepCopy;
  }

  async toggleShapeVisibility(userSearch: UserSearch, searchedShapes: UserSearch[] = []) {

    userSearch.visible = !userSearch.visible;

    const deepCopyOfshapesSearchedByUser = cloneDeep(searchedShapes);

    await this.updateLinesAndPointsVisibility(userSearch);

    if (userSearch.visible) {
      const clonedResponse = cloneDeep(userSearch.response);
      const groupedElements = await this.categorizeFeatures(clonedResponse);
      const allFeatures = [...groupedElements.points, ...groupedElements.lineStrings];
      await this.drawLinesAndPointsOnMap(allFeatures);
    }

    this.selectedShape = !!userSearch.visible ? userSearch : undefined;
    await this.redrawVisibleSearchedShapes(userSearch, deepCopyOfshapesSearchedByUser);
  }

  private async updateLinesAndPointsVisibility(userSearch: UserSearch) {
    const groupedElements = this.categorizeFeatures(userSearch.response);
    this._points = await this.toggleVisibleState((await groupedElements).points, userSearch.mapFeatures.points, userSearch.visible);
    this._lineStrings = await this.toggleVisibleState((await groupedElements).lineStrings, userSearch.mapFeatures.lineStrings, userSearch.visible);
  }

  private async toggleVisibleState(itemsToUpdate: any[], referenceData: any[], isVisible: boolean): Promise<any[]> {
    const referenceIds = new Set(referenceData.map(item => item.id));
    return itemsToUpdate.map(item => ({
      ...item,
      visible: referenceIds.has(item.id) ? isVisible : item.visible
    }));
  }

  async redrawVisibleSearchedShapes(currentShapeSearch: UserSearch, searchedShapes: UserSearch[]) {
    const visibleSearchedShapes = searchedShapes.filter(shape =>
      shape !== currentShapeSearch && shape.visible
    );

    if (visibleSearchedShapes.length > 0) {
      let allFeatures: any[] = [];

      for (const shape of visibleSearchedShapes) {
        if (await this.areTheLinesAndPointsInsideTheShapeVisible(shape)) {
          const clonedShapeResponse = cloneDeep(shape.response);
          allFeatures = allFeatures.concat(clonedShapeResponse);
        }
      }

      await this.drawLinesAndPointsOnMap(allFeatures);
    }
  }


  async areTheLinesAndPointsInsideTheShapeVisible(shape: any): Promise<boolean> {
    return shape.visible;
  }

  async areMoreThanOneShapeSearchedByTheUser(shapesSearchedByUser: any): Promise<boolean> {
    return shapesSearchedByUser.length > 1;
  }

  resetProperties() {
    this._lineStrings = [];
    this._points = [];
    this.shapesSearchedByUser = [];
    this.versionOptionsSelectedGroupedByProjectType = [];
  }

  goToPointPosition(point: olMapPoint) {
    const coordinates = point.flatCoordinates;
    this.olMap.moveTo(coordinates[1], coordinates[0], 15, 2500);
  }

}
