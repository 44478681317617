<div class="container">

    <div class="row justify-content-end">
        <div class="col-auto d-flex justify-content-end gap-2">
            <!-- show map button -->
            <dx-button class="shape-import-button main-background-theme"
                text="{{'constructive-feasibility-show-projects.button' | translate}}" (onClick)="navigateTo()">
            </dx-button>

            <!-- import shape button -->
            <dx-button class="shape-import-button main-background-theme"
                text="{{'constructive-feasibility-import-shape.button' | translate}}"
                (onClick)="openFileUploaderPopup()">
            </dx-button>
        </div>
    </div>


    <!-- Fila para el DataGrid -->
    <div class="row">
        <div class="col-12">
            <div class="data-grid">
                <dx-data-grid [dataSource]="lastUpdatesShapes" [showBorders]="true" [showRowLines]="true"
                    [allowColumnResizing]="true" [paging]="{ pageSize: 5 }" [filterRow]="{ visible: false }"
                    [searchPanel]="{ visible: true, width: 200, placeholder: 'Buscar...' }">

                    <!-- Paginación -->
                    <dxo-pager [visible]="true" [displayMode]="'full'" [showPageSizeSelector]="false" [showInfo]="false"
                        [showNavigationButtons]="true">
                    </dxo-pager>

                    <dxi-column [caption]="''" [width]="45" [cellTemplate]="'customCellTemplate'">
                    </dxi-column>

                    <div *dxTemplate="let data of 'customCellTemplate'; let index = index">
                        <div *ngIf="data && data.rowIndex === 0">
                            <svg width="30" height="30" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M16.9414 8.73591C16.2551 8.36932 15.7811 7.65741 15.7515 6.83225C15.7505 6.80496 15.75 6.77754 15.75 6.75C15.75 6.19642 15.949 5.69126 16.2801 5.29919C16.694 4.8092 17.3105 4.5 18 4.5C19.2426 4.5 20.25 5.50736 20.25 6.75C20.25 7.75592 19.5893 8.6095 18.6764 8.89677C18.4638 8.96365 18.2369 9 18 9C17.6173 9 17.2569 8.90445 16.9414 8.73591Z"
                                        fill="#5A1438" />
                                    <path
                                        d="M14.25 6.75C14.25 8.56231 15.5356 10.0743 17.2446 10.4239C17.2449 10.4492 17.2451 10.4746 17.2451 10.5V13.65L18.1949 14.7897C19.0091 15.7667 18.3143 17.25 17.0426 17.25H6.94769C5.67593 17.25 4.9812 15.7667 5.79536 14.7897L6.74513 13.65V10.5C6.74513 7.85523 8.70078 5.66724 11.2449 5.3032V4.5C11.2449 4.08579 11.5807 3.75 11.9949 3.75C12.4091 3.75 12.7449 4.08579 12.7449 4.5V5.30313C13.3213 5.38555 13.8675 5.56159 14.3676 5.81536C14.2908 6.11427 14.25 6.42748 14.25 6.75Z"
                                        fill="#5A1438" />
                                    <path
                                        d="M8.56274 18.75C8.75015 19.1794 9.01645 19.5701 9.34802 19.9017C9.69624 20.2499 10.1096 20.5261 10.5646 20.7145C11.0196 20.903 11.5072 21 11.9997 21C12.4921 21 12.9798 20.903 13.4347 20.7145C13.8897 20.5261 14.3031 20.2499 14.6513 19.9017C14.9829 19.5701 15.2492 19.1794 15.4366 18.75H8.56274Z"
                                        fill="#5A1438" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    >
                    <dxi-column dataField="shapeID" [width]="70" [caption]="'N° sol.'"></dxi-column>

                    <dxi-column dataField="name" [width]="200" [caption]="'Proyecto'"></dxi-column>

                    <dxi-column dataField="version" [width]="60" [caption]="'Ver.'"></dxi-column>

                    <dxi-column dataField="entityShape" [caption]="'Tipo de proyecto'"></dxi-column>

                    <dxi-column dataField="creation_Date" [caption]="'Fecha de importación'"></dxi-column>

                    <dxi-column dataField="userID" [caption]="'Usuario importación'"></dxi-column>

                    <dxi-column dataField="link_Date" [width]="100" [caption]="'Fecha Link'"></dxi-column>

                    <dxi-column dataField="user_Link" [caption]="'Usuario factibilidad'"></dxi-column>

                    <dxi-column dataField="Close_Date" [caption]="'Fecha factibilidad'"></dxi-column>


                    <dxi-column width="90" [caption]="''" cellTemplate="actionsCellTemplate"></dxi-column>
                    <div *dxTemplate="let d of 'actionsCellTemplate'">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxFlex="100" style="text-align: center;">

                                <dx-button class="main-background-theme grid-button" icon="fa fa-globe"
                                    (onClick)="navigateTo(d)"></dx-button>

                                <dx-button class="main-background-theme grid-button" icon="fas fa-comment"
                                    (onClick)="openCommentSectionPopup()"></dx-button>
                            </div>
                        </div>
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>



</div>


<dx-popup [(visible)]="fileUploaderPopupVisible" [showCloseButton]="true" [closeOnOutsideClick]="false" [width]="'80vh'"
    [height]="'80vh'" [title]="'constructive-feasibility.popup-import-title' | translate">

    <div class="popup-import-files-container">

        <div class="content-import-files-container">

            <!-- Radio Group for Project Type -->
            <div class="radio-group-container">
                <dx-radio-group [(value)]="project" [items]="projectOptions" displayExpr="title" valueExpr="id"
                    layout="horizontal"></dx-radio-group>
            </div>

            <!-- Elementos exclusivos para 'Nuevo Proyecto' -->
            <div *ngIf="project === newProjectOption" class="new-project-container">

                <!-- Dynamic Title Label Below Radio Groups -->
                <div class="col">
                    <label>{{ getPopupTitle() }}</label>
                </div>

                <!-- SelectBoxes for Country and Province -->
                <div class="select-boxes-container">
                    <div class="col">
                        <dx-select-box [(value)]="selectedCountry" [dataSource]="countries"
                            [label]="'general.country' | translate" displayExpr="name" valueExpr="id"
                            [searchEnabled]="true" placeholder="" [width]="'100%'" labelMode="floating">
                        </dx-select-box>
                    </div>

                    <div class="col">
                        <dx-select-box labelMode="floating" [label]="'general.province' | translate"
                            [(value)]="selectedProvince" [dataSource]="provinces" displayExpr="name" valueExpr="id"
                            [searchEnabled]="true" placeholder="" [width]="'100%'">
                        </dx-select-box>
                    </div>
                </div>

                <!-- Project Name Input -->
                <div class="col">
                    <dx-text-box id="inputProjectName" [(value)]="projectName" [placeholder]="" stylingMode="outlined"
                        style="width: 100%;" labelMode="floating"
                        [label]="'constructive-feasibility-input-project-name.label' | translate">
                    </dx-text-box>
                </div>
            </div>

            <!-- Elementos exclusivos para 'Versión Existente' -->
            <div *ngIf="project === existingProjectOption" class="existing-project-container">
                <div class="col">
                    <dx-select-box [(value)]="selectedProject" [dataSource]="element_datasource" displayExpr="name"
                        [searchEnabled]="true" [width]="'100%'" labelMode="floating"
                        [label]="'constructive-feasibility-select-a-project.label' | translate">
                    </dx-select-box>
                </div>
            </div>
        </div>

        <!-- Radio Group for Project Type -->
        <div class="project-type-radio-group">
            <label for="inputProjectType">{{'constructive-feasibility-project-type.title' | translate}}</label>
            <dx-radio-group [(value)]="selectedProjectType"
                [items]="['Factibilidad', 'Cao', 'Municipio']"></dx-radio-group>
        </div>

        <!-- File Uploader -->
        <div class="footer-container">
            <div class="fileuploader-container">
                <dx-file-uploader #fileUploader [showFileList]="true" [multiple]="false" [maxFileSize]="5000000"
                    selectButtonText="{{ 'common-odf.select.file' | translate }}"
                    labelText="{{ 'common-odf.drop.file' | translate }}" accept=".zip" uploadMode="useForm"
                    (onValueChanged)="onFileSelected($event)">
                </dx-file-uploader>
            </div>

            <div class="fileuploader-button">
                <dx-button (onClick)="handleZipUpload()">{{ 'common-odf.upload.file' | translate }}</dx-button>
            </div>
        </div>
    </div>
</dx-popup>





<dx-popup [(visible)]="commentSectionPopupVisible" [showCloseButton]="true" [closeOnOutsideClick]="true"
    title="Importar archivo">
    <div class="comment-section">
        <app-odf-comment-section></app-odf-comment-section>
    </div>
</dx-popup>

<!-- Progress Bar -->
<div class="custom-loader-backdrop" *ngIf="loading">
    <div class="custom-loader">
        <h5>{{ 'constructive-feasibility-uploading-files.title' | translate }}: {{ processingProgress }}%</h5>
        <div class="progress mt-2" style="height: 20px; width: 300px;">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                [style.width.%]="processingProgress">
            </div>
        </div>
    </div>
</div>