<div class="map-container">
    <dx-drawer #drawer [openedStateMode]="'shrink'" [position]="'left'" [revealMode]="'slide'" template="template"
        [(opened)]="drawerOpened" [height]="'100%'" [closeOnOutsideClick]="false"
        [style.overflow]="showOverflow ? 'auto' : 'hidden'">

        <div *dxTemplate="let data of 'template'">
            <div class="search-panel">
                <!-- First Accordion (Project search panel) -->
                <dx-accordion #accordion [dataSource]="ds_accordion_search" [collapsible]="true" [multiple]="false"
                    [animationDuration]="300" [selectedItems]="accordion_search_selected" id="search_accordion"
                    style="background-color: #f8f8f8">
                    <div *dxTemplate="let item of 'title'">
                        <h1 class="main-secondary-text">{{ item.title }}</h1>
                    </div>
                    <div *dxTemplate="let item of 'item'">
                        <div class="form-group">
                            <dx-select-box [(value)]="selectedProject" [dataSource]="element_datasource"
                                displayExpr="name" [searchEnabled]="true" [width]="'100%'" labelMode="floating"
                                [label]="'constructive-feasibility-select-a-project.label' | translate">
                            </dx-select-box>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                            <dx-button class="search-button main-background-theme" [text]="'general.search' | translate"
                                [hint]="'general.search' | translate" (onClick)="search()">
                            </dx-button>
                        </div>
                    </div>
                </dx-accordion>

                <!-- Second Accordion (versions checkboxes) -->
                <dx-accordion #accordionCheckbox [dataSource]="ds_accordion_checkbox" [collapsible]="true"
                    [multiple]="false" [animationDuration]="300" [selectedItems]="accordion_checkbox_selected"
                    id="checkbox_accordion" style="background-color: #f8f8f8">
                    <div *dxTemplate="let item of 'title'">
                        <h1 class="main-secondary-text">{{ item.title }}</h1>
                    </div>

                    <div *dxTemplate="let item of 'item'">
                        <div *ngFor="let option of checkboxOptions">
                            <!-- Parent option -->
                            <mat-checkbox [(ngModel)]="option.selected" (change)="toggleAllSubOptions(option)">
                                {{ option.label }}
                            </mat-checkbox>

                            <!-- Child options (sub-options) -->
                            <div *ngIf="option.subOptions.length > 0" class="child-options ml-3">
                                <div *ngFor="let subOption of option.subOptions" class="child-option">
                                    <mat-checkbox [(ngModel)]="subOption.selected"
                                        (change)="updateParentCheckbox(option)">
                                        {{ subOption.label }}
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>

                        <div style="text-align: right; margin-top: 30px">
                            <dx-button class="search-button main-background-theme" [text]="'general.draw' | translate"
                                [hint]="'general.draw' | translate" (onClick)="drawShape()">
                            </dx-button>
                        </div>

                    </div>
                </dx-accordion>
            </div>
        </div>

        <div class="drawer-collapser" (click)="toggleDrawer()">
            <i *ngIf="!drawerOpened" class="fas fa-chevron-right"></i>
            <i *ngIf="drawerOpened" class="fas fa-chevron-left"></i>
        </div>

        <div class="map-wrapper">
            <ol-map id="mapContainer" #olMap [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                [height]="'100%'" (onMapReady)="onMapReady($event)" [xygoMapSelected]="xygoMapSelected"
                [googleMapSelected]="googleMapSelected" [openStreetMapSelected]="openStreetMapSelected"
                [points]="points" [linestring]="lineStrings"></ol-map>
        </div>

        <div class="bottom-right-corner-top-buttons">
            <div class="bottom-buttons">

                <dx-button id="share" class="main-background-theme" [icon]="shareIcon" [disabled]="dowloadImg"
                    [class.active]="mapButtonClicked" [ngClass]="{ 'main-background-theme': mapButtonClicked }">
                </dx-button>

                <dx-button id="trace" class="fence main-background-theme" [icon]="traceIcon"
                    [disabled]=" mapButtonClicked" [class.active]="dowloadImg"
                    [ngClass]="{ 'main-background-theme': dowloadImg }">
                </dx-button>
            </div>
        </div>

        <div class="bottom-right-corner-buttons">

            <div class="top-buttons">
                <div class="eraser-button"></div>
                <dx-button id="clear-searches-performed" icon="fas fa-eraser" class="main-background-theme"
                    [ngbTooltip]="'forms.map-viewer.eraser' | translate " [disabled]=" mapButtonClicked "
                    [ngClass]="{ 'main-background-theme': dowloadImg }" [class.active]="eraserActive">
                </dx-button>
            </div>
            <div class="bottom-buttons">

                <dx-button id="mapLayer" class="main-background-theme" icon="fa fa-map" [disabled]="dowloadImg"
                    [class.active]="mapButtonClicked" [ngClass]="{ 'main-background-theme': mapButtonClicked }"
                    (onClick)="selectMap()">
                </dx-button>

                <dx-button id="download-map" class="fence main-background-theme" icon="fas fa-download"
                    [disabled]=" mapButtonClicked" [class.active]="dowloadImg"
                    [ngClass]="{ 'main-background-theme': dowloadImg }"
                    [ngbTooltip]="'forms.map-viewer.dowload' | translate " (onClick)="downloadMap()">
                </dx-button>
            </div>
        </div>
    </dx-drawer>

    <dx-popover target="#mapLayer" position="bottom" [width]="200" [(visible)]="mapButtonClicked">
        <div *dxTemplate="let data = model; of: 'content'" id="map_popover">
            <h5 style="font-size:1.2em;">{{'forms.map-viewer.maps' | translate}}</h5>
            <dx-radio-group [(value)]="selectedMapOption" (valueChange)="selectMapOption($event, selectedMapOption)"
                [items]="mapOptions" displayExpr="name" valueExpr="name" class="mb-2 custom-radio-group">
            </dx-radio-group>
        </div>
    </dx-popover>
</div>