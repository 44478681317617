import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  NgZone,
  ElementRef,
} from '@angular/core';
import { Cons } from '@app/@shared/cons/cons';
import { Connection } from '@app/@shared/model/connection.model';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import { MainService } from '@app/@shared/services/main.service';
import { SearchPanelModel } from '@app/model/search-panel.model';
import { TranslateService } from '@ngx-translate/core';
import { DxAccordionComponent, DxSelectBoxComponent, DxTextBoxComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';

import { GoogleService } from '@shared/services/map-elements-services/google.service';
import { AddressAutocompleteComponent } from './adress-autcomplete/address-autocomplete.component';
import { GoogleMapView } from './google-map-view/google-map-view.component';
import { ProximityDetailComponent } from './proximity-detail/proximity-detail.component';

class SearchPanelTypes {
  public static _unselected: any = { name: '', description: '' };
  public static _container: any = { name: 'Container', description: '(Poleped, Ductenclosure, Duct)' };
  public static _device: any = { name: 'Device', description: '(Fiberspliceenclosure, Fibernode, Cabinet)' };
  public static _trace: any = { name: 'Trace', description: '(Segment/Fiberspan - Fibers)' };
  public static _infrastructure: any = { name: 'Infrastructure', description: '(Building, Headend - ODF)' };
  public static _service: any = { name: 'Service', description: '' };
  public static _polygon: any = { name: 'Polygon', description: '(Boundary)' };

}

enum Distance {
  oneHundredM = '100 m',
  threeHundredM = '300 m',
  fiveHundredM = '500 m',
  oneKm = '1 Km',
  oneFiveKm = '1.5 Km',
  twoKm = '2 Km',
  twoFiveKm = '2.5 Km',
  threeKm = '3 Km'
}

enum SelectedProximityType {
  Service = 'Servicio',
  Element = 'Elemento'
}

enum ValidSelectedTypeForNetworkType {
  Device = 'Device',
  Trace = 'Trace',
  Infrastructure = 'Infrastructure'
}

@Component({
  selector: 'search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
})
export class SearchPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Output() connectionSelected = new EventEmitter<Connection>();
  @Input() erase;
  @Output() textBoxFocusIn: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() textBoxFocusOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() mapViewer: any;
  @ViewChild('addressAutocomplete') addressAutocomplete!: AddressAutocompleteComponent;
  @ViewChild('accordion', { static: false }) accordion!: DxAccordionComponent;
  @Output() areaExtentCalculated = new EventEmitter<{ x: number, y: number }[]>();
  @ViewChild(GoogleMapView) googleMapViewComponent!: GoogleMapView;
  @ViewChild(ProximityDetailComponent) proximityDetailComponent!: ProximityDetailComponent;
  @ViewChild('networkTypeElement', { static: false }) networkTypeElement: ElementRef;
  @ViewChild('serviceNameElement', { static: false }) serviceNameElement: ElementRef;
  @Input() textBoxValue: string;
  @Input() dataFromMapViewer: any;
  @Output() selectedServiceToShowInMap: EventEmitter<any> = new EventEmitter<any>();

  selectBoxValue: any = {};
  ds_accordion_search = [];
  accordion_search_selected = [];
  searchTypes = [];
  private subscription: Subscription;

  selectedType: any;
  selectedTypeForNetwork = ValidSelectedTypeForNetworkType;
  selectedDistance: Distance = Distance.oneHundredM;
  selectedAddress: string = '';
  inputAddress: string = '';
  serviceFilter: string = '';

  showDistance = true;
  addressOrCoordinates: string;


  coordinates: { address: string, latitude: number, longitude: number }[] = [];

  ds_types: SearchPanelTypes[] = [
    SearchPanelTypes._unselected,
    SearchPanelTypes._container,
    SearchPanelTypes._device,
    SearchPanelTypes._trace,
    SearchPanelTypes._infrastructure,
    SearchPanelTypes._polygon

  ];

  km_selection: Distance[] = [
    Distance.oneHundredM,
    Distance.threeHundredM,
    Distance.fiveHundredM,
    Distance.oneKm,
    Distance.oneFiveKm,
    Distance.twoKm,
    Distance.twoFiveKm,
    Distance.threeKm
  ];

  network_type: SelectedProximityType[] = [
    SelectedProximityType.Service,
    SelectedProximityType.Element
  ]
  selectedRowData: any;
  onProximitySearch: boolean = false;
  dataAvailableServicesReceived: boolean = false;
  serviceNameOptionSelected: any;
  isServiceNameOptionSelected: boolean;

  reset() {
    this.selectBoxValue = null
    this.searchModel.searchType = this.searchTypes[0]
  }

  element_datasource: CustomStore;

  searchModel: SearchPanelModel = {};
  searchElementDisabled = true;
  showElements = true;
  elementSelected = false;
  serviceSelected = false;
  networkTypeSelected = false;
  selectedServiceOrElement: SelectedProximityType | null = null;
  SelectedProximityType = SelectedProximityType;
  selectedNetworkType = null;
  proximitySelected = false;
  proximitySearched = false;

  searchPanelTypes = SearchPanelTypes;

  polenoFilterOptions = [{ name: this.trans.instant('general.all-network') }, { name: 'CORP' }, { name: 'FTTH' }];

  coordinatesOrAddressOptions = [{ name: this.trans.instant('general.address'), value: 'address' }, { name: this.trans.instant('general.coordinates'), value: 'coordinate' }];

  serviceNameOption = [
    { name: this.trans.instant('general.all'), value: undefined },
    { name: 'METROETHERNET', value: 'ME' },
    { name: 'IPRAN', value: 'IPRAN' },
    { name: 'GPON', value: 'GPON' },
    { name: 'DWDM', value: 'DWDM' }
  ];


  coordinate_or_addres = this.coordinatesOrAddressOptions;


  service_or_element = [
    this.polenoFilterOptions[0].name,
    this.polenoFilterOptions[1].name,
    this.polenoFilterOptions[2].name,

  ];

  serviceOptionList = [
    this.serviceNameOption[0].name,
    this.serviceNameOption[1].name,
    this.serviceNameOption[2].name,
    this.serviceNameOption[3].name,
    this.serviceNameOption[4].name
  ];

  _entity: any;


  @Input('entity')
  get entity() {
    return this._entity;
  }
  set entity(value) {
    if (value == this._entity) {
      return;
    }
    this._entity = value;

    this.accordion_search_selected = [];
  }
  @Output() serviceFilterOutput = new EventEmitter<any>();
  @Output() onSearching = new EventEmitter<SearchPanelModel>();
  @Output() switchChanged = new EventEmitter<boolean>();

  address: string = '';

  constructor(
    public trans: TranslateService,
    public allDatasources: DataSourceService,
    private mainService: MainService,
    private googleService: GoogleService,

    private ngZone: NgZone
  ) {
    this.searchTypes.push(this.trans.instant('forms.map-viewer.search-by-os'));
    this.searchTypes.push(
      this.trans.instant('forms.map-viewer.search-by-element')
    );
    this.searchTypes.push(
      this.trans.instant('forms.map-viewer.search-by-proximity')
    );
    this.ds_accordion_search.push({
      title: this.trans.instant('forms.map-viewer.search-panel'),
    });

  }

  @ViewChild('service_validator') service_validator;
  @ViewChild('element_validator') element_validator;
  @ViewChild('selectTextBox') selectTextBox: DxTextBoxComponent;
  @ViewChild('selectBoxOS') selectBoxOS: DxSelectBoxComponent;

  ngOnInit() {
    this.searchModel = {
      searchType: this.searchTypes[0],
      id: 0,
      controller: Cons._SERVICE,
    };

    this.element_datasource = this.allDatasources.servicesDatasource;

    if (!this.entity) {
      this.accordion_search_selected = [this.ds_accordion_search[0]];
    }

    if (this.erase) {
      this.subscription = this.mainService.clearSearchesPerformed$.subscribe(() => {
        this.reset();
      });
    }

    this.googleService.loadGoogleMapsScript().then(() => {
      this.initAutocomplete();
    }).catch(error => {
      console.error('Error loading Google Maps script:', error);
    });

    this.addressOrCoordinates = this.coordinate_or_addres[0].value;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.resetValidators();
    }
  }

  radioChanged($event) {
    this.searchModel.searchType = $event.value;

    if ($event.value == this.trans.instant('forms.map-viewer.search-by-element')) {
      this.searchElementDisabled = false;
      this.showElements = true;
      this.proximitySelected = false;
      this.proximitySearched = false;
      this.elementSelected = false;
    }
    else if ($event.value == this.trans.instant('forms.map-viewer.search-by-proximity')) {
      this.searchElementDisabled = false;
      this.showElements = false;
      this.proximitySelected = true;


    } else {
      this.searchElementDisabled = true;
      this.selectedType = SearchPanelTypes._unselected;
      this.proximitySelected = false;
      this.proximitySearched = false;
      this.showElements = true;
      this.elementSelected = false;

      this.searchModel = {
        searchType: this.searchTypes[0],
        id: 0,
        controller: SearchPanelTypes._service,
      };

      this.element_datasource = this.allDatasources.servicesDatasource;
    }
  }

  onElementTypePicked($event) {
    this.searchModel.controller = $event.value.name;
    // this.searchModel = {
    //   searchType: this.searchTypes[0],
    //   id: 0,
    //   controller: $event.value,
    // };

    this.selectBoxValue = null;


    if (this.selectTextBox != undefined)
      this.selectTextBox.value = '';

    if (this.element_validator)
      this.element_validator.instance.reset();

    switch (this.searchModel.controller) {
      case SearchPanelTypes._trace.name: {
        this.selectedType = SearchPanelTypes._trace;
        this.element_datasource = this.allDatasources.traceDatasource;

        break;
      }
      case SearchPanelTypes._container.name: {
        this.selectedType = SearchPanelTypes._container;
        this.element_datasource = this.allDatasources.containerDatasource;

        break;
      }
      case SearchPanelTypes._device.name: {
        this.selectedType = SearchPanelTypes._device;
        this.element_datasource = this.allDatasources.devicesDatasource;

        break;
      }
      case SearchPanelTypes._infrastructure.name: {
        this.selectedType = SearchPanelTypes._infrastructure;
        this.element_datasource = this.allDatasources.infrastructureDatasource;

        break;
      }
      case SearchPanelTypes._service.name: {
        this.selectedType = SearchPanelTypes._service;
        this.element_datasource = this.allDatasources.servicesDatasource;
        break;
      }
      case SearchPanelTypes._polygon.name: {
        this.selectedType = SearchPanelTypes._polygon;
        this.element_datasource = this.allDatasources.polygonsDatasource;
        break;
      }
      default: {
        this.searchModel.controller = SearchPanelTypes._service.name;
        break;
      }
    }
  }

  distanceChange($event) {
    this.selectedDistance = $event.value;
  }

  displayServiceName(service: any) {
    if (!service) {
      return '';
    }

    const date = (service?.aud_Date ?? '').split('T')?.[0];
    return `${service?.name}`;
  }

  search() {
    if (this.proximitySelected && this.addressAutocomplete != undefined) {
      const place = this.addressAutocomplete.getPlace();
      const { deletedPostalCodeAddress } = this.removePostalCodeFromGoogleAddress(place);
      this.selectedAddress = deletedPostalCodeAddress;

      //Reset searchModel
      if (this.serviceSelected) {
        this.searchModel = {
          searchType: this.searchTypes[2],
          id: 0,
          controller: Cons._SERVICE,
        };
      }

      this.searchModel.data = { selectedType: this.selectedType, selectedDistance: this.selectedDistance, selectedNetworkType: this.selectedNetworkType, serviceNameOptionSelected: this.serviceNameOptionSelected };
      this.serviceFilterOutput.emit(this.serviceFilter);
      this.onProximitySearch = true;
      this.dataAvailableServicesReceived = false;

      this.collapseAccordion();

    }

    this.entity = undefined;
    this.checkValidators();
    this.searchModel.id = this.selectBoxValue?.commonID;
    this.searchModel.name = this.selectBoxValue?.name;

    // if (!this.searchModel.id || !this.searchModel.name) {
    //   return;
    // }
    this.onSearching.emit(this.searchModel);
  }

  resetValidators() {
    if (this.service_validator) {
      this.service_validator.instance.reset();
    }

    if (this.element_validator) {
      this.element_validator.instance.reset();
    }
    this.onProximitySearch = false;
  }

  checkValidators() {
    if (this.service_validator) {
      this.service_validator.instance.validate();
    }

    if (this.element_validator) {
      this.element_validator.instance.validate();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showOverflow: boolean;


  onTextBoxFocusIn(event: any): void {
    this.textBoxFocusIn.emit(true);
  }

  onTextBoxFocusOut(event: any): void {
    this.textBoxFocusOut.emit(false);
  }

  initAutocomplete() {
    if (typeof google === 'undefined' || !google.maps || !google.maps.places) {
      console.error('La API de Google Maps no está cargada correctamente.');
      return;
    }

    const autocompleteInput = document.getElementById('autocomplete') as HTMLInputElement;

    if (!autocompleteInput) {
      console.error('No se encontró ningún elemento con el ID "autocomplete" en el DOM.');
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(autocompleteInput);

    autocomplete.addListener('place_changed', () => {
      const place: google.maps.places.PlaceResult = autocomplete.getPlace();
    });
  }

  onElementPicked($event) {
    this.selectedServiceOrElement = $event.value;
    this.elementSelected = (this.selectedServiceOrElement === SelectedProximityType.Element);
    this.serviceSelected = (this.selectedServiceOrElement === SelectedProximityType.Service);
  }

  onNetworkPicked($event) {
    this.selectedNetworkType = $event.value;
    this.networkTypeSelected = this.polenoFilterOptions.slice(0, 3).map(option => option.name).includes(this.selectedNetworkType);
  }

  onServicePicked($event) {
    this.serviceNameOptionSelected = $event.value;
    this.isServiceNameOptionSelected = this.serviceNameOption.slice(0, 3).map(option => option.name).includes(this.serviceNameOptionSelected);
  }

  callGoogleMapFunction() {
    this.proximitySearched = true;
    this.googleMapViewComponent.getGeocodeAddress(this.selectedAddress);
  }

  updateDataTable(newValue: [{}]) {
    this.proximityDetailComponent.data = newValue;
  }

  updateDataAvailableServices(value: [{}]) {
    this.proximityDetailComponent.dataAvailableServices = value;
    this.dataAvailableServicesReceived = true;
  }

  updateDataServicesNotAvailable(value: [{}]) {
    this.proximityDetailComponent.dataServicesNotAvailable = value;
  }

  selectedServiceToShow(event: any) {
    this.selectedRowData = event;
    this.selectedServiceToShowInMap.emit(this.selectedRowData);
  }

  onSwitchChangedInServiceDetail(newValue: boolean) {
    this.switchChanged.emit(newValue);
  }

  onServiceFilter(event: any): void {
    this.serviceFilter = event.value;
  }

  ngAfterViewChecked() {
    this.checkElementVisibility();
  }

  checkElementVisibility() {
    const element = this.networkTypeElement?.nativeElement;
    const serviceNameElement = this.serviceNameElement?.nativeElement;
    if (!element) {
      // Set the selected network type to null when the element is not visible
      this.selectedNetworkType = null;
      this.networkTypeSelected = false;

    } else if (!serviceNameElement) {
      this.serviceNameOptionSelected = null;
      this.isServiceNameOptionSelected = false;
    }
  }

  private removePostalCodeFromGoogleAddress(
    place: google.maps.places.PlaceResult | string
  ): { deletedPostalCodeAddress: string; postalCode: string | null } {
    if (typeof place === 'string') {
      return { deletedPostalCodeAddress: place, postalCode: null };
    }

    if (!place.address_components) {
      return { deletedPostalCodeAddress: place.formatted_address || '', postalCode: null };
    }

    let postalCode: string | null = null;

    const filteredComponents = place.address_components.filter((component) => {
      if (component.types.includes('postal_code')) {
        postalCode = component.long_name;
        return false;
      }
      return true;
    });

    const deletedPostalCodeAddress = filteredComponents.map((component) => component.long_name).join(', ');
    return { deletedPostalCodeAddress, postalCode };
  }

  onAddressOrCoordinatesChange(event: any): void {
    this.addressOrCoordinates = event.value;
  }

  collapseAccordion() {
    if (this.accordion) {
      this.accordion.instance.collapseItem(0);
    }
  }
}