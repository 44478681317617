<mat-chip-list>
  <mat-chip *ngFor="let service of services" (click)="center.emit(service)" [selected]="selectedService?.name === service.name">
    <button mat-icon-button matChipRemove (click)="toggleVisibility.emit(service)">
      <i [ngClass]="service.visible ? 'fa-eye' : 'fa-eye-slash'" class="fa" aria-hidden="true"></i>
    </button>
    <label class="mx-2">{{ service.name }}</label>
    <button *ngIf="showRemoveButton" matChipRemove (click)="remove.emit(service)">
      <i class="fa fa-times-circle" aria-hidden="true"></i>
    </button>
  </mat-chip>
</mat-chip-list>