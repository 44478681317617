import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, firstValueFrom, map, Observable, throwError } from 'rxjs';
import { MainService } from './main.service';
import { Cons } from '@app/@shared/cons/cons';
import { LastUpdatesInterface, ProjectQueryViewResponse } from '../model/interface/project-query-view-response';
import { platform } from 'os';
import { PlatformService } from '@app/auth/platform.service';

@Injectable({
    providedIn: 'root'
})
export class ShapeService {

    constructor(private http: HttpClient, private main: MainService, private platformService: PlatformService) { }


    createNewProject(files: File[], userID: number, projectName: string, entityShape: string): Observable<string> {
        this.main.chooseAPI(Cons._SHAPE);
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file, file.name);
        });

        formData.append('userID', userID.toString());
        formData.append('projectName', projectName);
        formData.append('entityShape', entityShape);
        formData.append('platformID', this.platformService.platformID.toString());



        return this.main.postFile(null, formData, `${Cons._SHAPETYPE}/CreateNewProject`).pipe(
            map(() => 'Files uploaded successfully')
        );
    }

    updateExistingProject(files: File[], userID: number, projectID: number, entityShape: string): Observable<string> {
        this.main.chooseAPI(Cons._SHAPE);
        const formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file, file.name);
        });
        formData.append('userID', userID.toString());
        formData.append('projectID', projectID.toString());
        formData.append('entityShape', entityShape);
        formData.append('platformID', this.platformService.platformID.toString());


        return this.main.postFile(null, formData, `${Cons._SHAPETYPE}/UpdateExistingProject`).pipe(
            map(() => 'Files uploaded successfully')
        );
    }

    getByID(id: any): Observable<any> {
        this.main.chooseAPI(Cons._SHAPE);
        return this.main.getByID(`${Cons._SHAPETYPE}/GetElementsByShapeID`, id).pipe(
            map((res) => {
                return <any>res;
            }),
            catchError((ex) => throwError(() => {
                return new Error(ex?.message);
            }))
        );
    }

    getAllProjects(): Observable<any> {
        this.main.chooseAPI(Cons._SHAPE);
        const platformID =  this.platformService.platformID.toString();
        return this.main.get(`${Cons._SHAPETYPE}/GetAllProjects`, platformID).pipe(
            map((res) => {
                return <any>res;
            }),
            catchError((ex) => throwError(() => {
                return new Error(ex?.message);
            }))
        );
    }

    getLastUpdates(): Observable<any> {
        this.main.chooseAPI(Cons._SHAPE);
        const platformID =  this.platformService.platformID.toString();
        return this.main.get(`${Cons._SHAPETYPE}/GetLastUpdates`, platformID).pipe(
            map((res) => {
                return <LastUpdatesInterface>res;
            }),
            catchError((ex) => throwError(() => {
                return new Error(ex?.message);
            }))
        );
    }

    getWithFilter(entity?: any, limit?: number, offset?: number): Observable<any> {
        this.main.chooseAPI(Cons._SHAPE);
        // let body = this.cleanUpForRequest(entity);
        let body;
        return this.main.getWithFilter(Cons._SHAPETYPE, entity, true, limit, offset).pipe(
            map((res) => {
                try {
                    return res.responseData.map((x) => {
                        x.commonID = x.projectID;
                        x.entityName = Cons._PROJECT;
                        return <ProjectQueryViewResponse>x;
                    });
                } catch (ex) {
                    return <string>(<any>res)._body.toString();
                }
            })
        );
    }

    getAllShapesByProjectID(id: any): Observable<any> {
        this.main.chooseAPI(Cons._SHAPE);
        return this.main.getByID(`${Cons._SHAPETYPE}/GetAllShapesByProjectID`, id).pipe(
            map((res) => {
                return <any>res;
            }),
            catchError((ex) => throwError(() => {
                return new Error(ex?.message);
            }))
        );
    }

}
