import { Injectable, NgZone } from '@angular/core';
import { IIntersectionObserver } from '../model/interface/IIntersectionObserver';

@Injectable({
  providedIn: 'root'
})
export class IntersectionObserverService implements IIntersectionObserver {
  private observer: IntersectionObserver;

  constructor(private ngZone: NgZone) {}

  observeElements(
    elements: Element[],
    callback: (entry: IntersectionObserverEntry) => void
  ): void {
    const options = {
      root: null,
      threshold: 0.1
    };

    this.observer = new IntersectionObserver((entries) => {
      this.ngZone.run(() => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            callback(entry);
          }
        });
      });
    }, options);

    elements.forEach(element => this.observer.observe(element));
  }

  disconnect(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
