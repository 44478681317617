<div class="map-container">
    <!-- Drawer component with search panel and map options -->
    <dx-drawer #drawer [openedStateMode]="'shrink'" [position]="'left'" [revealMode]="'slide'" template="template"
        [(opened)]="drawerOpened" [height]="'100%'" [closeOnOutsideClick]="false"
        [style.overflow]="showOverflow ? 'auto' : 'hidden'">

        <div *dxTemplate="let data of 'template'">
            <div class="container-fluid p-0">
                <div class="row">
                    <!-- Responsive column that adapts to different screen sizes -->
                    <div class="col-12">
                        <div class="search-panel">
                            <!-- First Accordion for project search panel -->
                            <dx-accordion #accordion [dataSource]="ds_accordion_search_title" [collapsible]="true"
                                [multiple]="false" [animationDuration]="300" [selectedItems]="accordion_search_selected"
                                id="search_accordion" class="project-search-panel-accordion">
                                <div *dxTemplate="let item of 'title'">
                                    <h1 class="main-secondary-text">{{ item.title }}</h1>
                                </div>
                                <div *dxTemplate="let item of 'item'">
                                    <div class="form-group">
                                        <!-- Dropdown for selecting a project -->
                                        <dx-select-box [(value)]="selectedProject" [dataSource]="element_datasource"
                                            displayExpr="name" [searchEnabled]="true" [width]="'100%'"
                                            labelMode="floating"
                                            [label]="'constructive-feasibility-select-a-project.label' | translate">
                                        </dx-select-box>
                                    </div>
                                    <div style="text-align: right; margin-top: 30px">
                                        <!-- Search button -->
                                        <dx-button class="search-button main-background-theme"
                                            [text]="'general.search' | translate" [hint]="'general.search' | translate"
                                            (onClick)="search()">
                                        </dx-button>
                                    </div>
                                </div>
                            </dx-accordion>

                            <!-- Second Accordion for version checkboxes -->
                            <dx-accordion #accordionCheckbox [dataSource]="ds_accordion_checkbox_title"
                                [collapsible]="true" [multiple]="false" [animationDuration]="300"
                                [selectedItems]="accordion_checkbox_selected" id="checkbox_accordion"
                                class="version-search-panel-accordion" [disabled]="!isAccordionEnabled">

                                <div *dxTemplate="let item of 'title'">
                                    <h1 class="main-secondary-text">{{ item.title }}</h1>
                                </div>

                                <div *dxTemplate="let item of 'item'">
                                    <div style="display: flex; flex-direction: column; height: 100%;">
                                        <h1 class="accordion-project-name-title main-secondary-text">
                                            {{ selectedProject.name }}
                                        </h1>

                                        <div class="scroll-container">
                                            <div *ngFor="let option of checkboxOptions" style="margin-bottom: 2vh;">
                                                <mat-checkbox [(ngModel)]="option.selected" (change)="toggleAllSubOptions(option)" class="parent-option">
                                                    {{ option.label }}
                                                </mat-checkbox>

                                                <div *ngIf="option.subOptions.length > 0" class="child-options ml-3">
                                                    <div *ngFor="let subOption of option.subOptions">
                                                        <mat-checkbox [(ngModel)]="subOption.selected" (change)="updateParentCheckbox(option)" class="child-option">
                                                            {{ subOption.label }}
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style="text-align: right; margin-top: 30px">
                                            <dx-button class="search-button main-background-theme"
                                                [text]="'constructive-feasibility-general-draw.button' | translate"
                                                [hint]="'general.draw' | translate" (onClick)="drawShape()">
                                            </dx-button>
                                        </div>
                                    </div>
                                </div>
                            </dx-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Button to collapse or expand the drawer -->
        <div class="drawer-collapser" (click)="toggleDrawer()">
            <i *ngIf="!drawerOpened" class="fas fa-chevron-right"></i>
            <i *ngIf="drawerOpened" class="fas fa-chevron-left"></i>
        </div>

        <!-- Map container to display the map -->
        <div class="map-wrapper">

            <div class="chip-list-container" *ngIf="shapesSearchedByUser?.length">
                <app-service-chip-list [services]="shapesSearchedByUser" [selectedService]="selectedShape"
                    [showRemoveButton]="false"
                    (toggleVisibility)="toggleShapeVisibility($event, shapesSearchedByUser)"
                    (center)="toggleShapeVisibility($event, shapesSearchedByUser)"></app-service-chip-list>
            </div>

            <!-- OpenLayers map component -->
            <ol-map id="mapContainer" #olMap [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                [height]="'100%'" (onMapReady)="onMapReady($event)" [xygoMapSelected]="xygoMapSelected"
                [googleMapSelected]="googleMapSelected" [openStreetMapSelected]="openStreetMapSelected"
                [points]="_points" [linestring]="_lineStrings"
                [enableClusteredFeatures]="enableClusteredFeatures"></ol-map>
        </div>

        <!-- Top right corner buttons (e.g., share, trace) -->
        <div class="top-right-corner-buttons">
            <div class="top-buttons">

                <!-- Share button -->
                <dx-button id="share" class="main-background-theme" [icon]="shareIcon" [disabled]="dowloadImg"
                    [class.active]="mapButtonClicked" [ngClass]="{ 'main-background-theme': mapButtonClicked }">
                </dx-button>

                <!-- Trace button -->
                <dx-button id="trace" class="fence main-background-theme" [icon]="traceIcon"
                    [disabled]=" mapButtonClicked" [class.active]="dowloadImg"
                    [ngClass]="{ 'main-background-theme': dowloadImg }">
                </dx-button>
            </div>
        </div>

        <!-- Bottom top right corner buttons -->
        <div class="bottom-right-corner-buttons">
            <div class="top-buttons">
                <!-- Eraser button to clear selections -->
                <div class="eraser-button"></div>
                <dx-button id="clear-searches-performed" icon="fas fa-eraser" class="main-background-theme"
                    [ngbTooltip]="'forms.map-viewer.eraser' | translate " [disabled]=" mapButtonClicked "
                    [ngClass]="{ 'main-background-theme': dowloadImg }" [class.active]="eraserActive">
                </dx-button>
            </div>
            <div class="bottom-buttons">

                <!-- Map layer button to select map -->
                <dx-button id="mapLayer" class="main-background-theme" icon="fa fa-map" [disabled]="dowloadImg"
                    [class.active]="mapButtonClicked" [ngClass]="{ 'main-background-theme': mapButtonClicked }"
                    (onClick)="selectMap()">
                </dx-button>

                <!-- Download map button -->
                <dx-button id="download-map" class="fence main-background-theme" icon="fas fa-download"
                    [disabled]=" mapButtonClicked" [class.active]="dowloadImg"
                    [ngClass]="{ 'main-background-theme': dowloadImg }"
                    [ngbTooltip]="'forms.map-viewer.dowload' | translate " (onClick)="downloadMap()">
                </dx-button>
            </div>
        </div>

    </dx-drawer>

    <!-- Popover for selecting map options -->
    <dx-popover target="#mapLayer" position="bottom" [width]="200" [(visible)]="mapButtonClicked">
        <div *dxTemplate="let data = model; of: 'content'" id="map_popover">
            <h5 style="font-size:1.2em;">{{'forms.map-viewer.maps' | translate}}</h5>
            <!-- Radio group to select map options -->
            <dx-radio-group [(value)]="selectedMapOption" (valueChange)="selectMapOption($event, selectedMapOption)"
                [items]="mapOptions" displayExpr="name" valueExpr="name" class="mb-2 custom-radio-group">
            </dx-radio-group>
        </div>
    </dx-popover>
</div>

<!-- Loading panel to display when loading data -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: 'dx-drawer' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>