import { Component, OnInit, ViewChild } from '@angular/core';
import { AppRoutingService } from '@app/app-routing.service';
import { DxFileUploaderComponent } from 'devextreme-angular';
import * as JSZip from 'jszip';
import { Cons } from '@app/@shared/cons/cons';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { catchError, first, firstValueFrom, forkJoin, interval, map, Observable, of, Subject, switchMap, takeUntil, takeWhile, tap, throwError, timer } from 'rxjs';
import { CredentialsService } from '@app/auth';
import { ShapeService } from '@app/@shared/services/shape.service';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import CustomStore from 'devextreme/data/custom_store';
import { PlatformService } from '@app/auth/platform.service';
import { LastUpdatesInterface, ProjectQueryViewResponse } from '@app/@shared/model/interface/shapeDataInterface';
import { ToastrService } from 'ngx-toastr';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-constructive-feasibility',
  templateUrl: './constructive-feasibility.component.html',
  styleUrls: ['./constructive-feasibility.component.scss']
})
export class ConstructiveFeasibilityComponent implements OnInit {

  notificationBellIcon = '../../../assets/icons/notification-bell-svgrepo-com.png';
  fileUploaderPopupVisible: boolean = false;
  commentSectionPopupVisible: boolean = false;
  path = '/constructive-feasibility-map';
  project: string = 'Nuevo Proyecto';
  selectedProjectType: string = 'Factibilidad';
  projectName: string = '';
  projectType: string;
  openNewTab = true;
  selectedCountry: string;
  selectedProvince: string;
  selectedProject: ProjectQueryViewResponse = null;
  currentUserID: number | null = null;
  file: File | null = null;
  geoJSONFile: File;
  newProjectName: string;
  element_datasource: CustomStore;
  newProjectOption: string = 'newProject';
  existingProjectOption: string = 'existingProject';
  lastUpdatesShapes: LastUpdatesInterface[] = [];
  loading: boolean = false;
  INVALID_CONTAINER_MODEL = "constructive-feasibility-feature-invalid-container-model.error";
  INVALID_DEVICE_MODEL = "constructive-feasibility-feature-invalid-device-model.error";
  INVALID_INFRASTRUCTURE_MODEL = "constructive-feasibility-feature-invalid-infrastructure-model.error";
  INVALID_TRACE_MODEL = "constructive-feasibility-feature-invalid-trace-model.error";
  FILES_SUCCESSFULLY_UPLOADED = "constructive-feasibility-files-uploaded-successfully";
  uploadProgress: number = 0;
  processingProgress: number = 0;
  pollingInterval: any = null;
  taskId: string = null;

  data = [
    {
      proyectID: '50001',
      name: 'AR-COR-VILLA-MARIA',
      creation_Date: new Date('2024-01-10').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      userID: 'EXA36482',
      user_Link: new Date('2024-01-12').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      usuarioFactibilidad: 'Pablo Jose',
      close_Date: new Date('2024-01-15').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      version: '1',
      entityShape: 'Cao',
      link_Date: new Date('2024-01-10').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
    },
    {
      proyectID: '50002',
      name: 'AR-COR-VILLA-MARIA',
      creation_Date: new Date('2024-01-11').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      userID: 'EXA36322',
      user_Link: new Date('2024-01-13').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      usuarioFactibilidad: 'Juan Carlos',
      close_Date: new Date('2024-01-13').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      version: '2',
      entityShape: 'Cao',
      link_Date: new Date('2024-01-13').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
    },
    {
      proyectID: '50003',
      name: 'AR-SFE-ROSARIO',
      creation_Date: new Date('2024-01-12').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      userID: 'EXA88822',
      user_Link: new Date('2024-01-14').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      usuarioFactibilidad: 'Juan Perez',
      close_Date: new Date('2024-01-14').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }),
      version: '1',
      entityShape: 'Factibilidad',
      link_Date: new Date('2024-01-14').toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
    }
  ];

  countries = [
    { id: '1', name: 'AR' }
  ];

  provinces = [
    { "id": "1", "name": "BUE", "description": "Buenos Aires" },
    { "id": "2", "name": "CAT", "description": "Catamarca" },
    { "id": "3", "name": "CHA", "description": "Chaco" },
    { "id": "4", "name": "CHU", "description": "Chubut" },
    { "id": "5", "name": "CBA", "description": "Córdoba" },
    { "id": "6", "name": "COR", "description": "Corrientes" },
    { "id": "7", "name": "ERI", "description": "Entre Ríos" },
    { "id": "8", "name": "FOR", "description": "Formosa" },
    { "id": "9", "name": "JUJ", "description": "Jujuy" },
    { "id": "10", "name": "LPA", "description": "La Pampa" },
    { "id": "11", "name": "LRI", "description": "La Rioja" },
    { "id": "12", "name": "MEN", "description": "Mendoza" },
    { "id": "13", "name": "MIS", "description": "Misiones" },
    { "id": "14", "name": "NEU", "description": "Neuquén" },
    { "id": "15", "name": "RNE", "description": "Río Negro" },
    { "id": "16", "name": "SAL", "description": "Salta" },
    { "id": "17", "name": "SJU", "description": "San Juan" },
    { "id": "18", "name": "SLU", "description": "San Luis" },
    { "id": "19", "name": "SCR", "description": "Santa Cruz" },
    { "id": "20", "name": "SFE", "description": "Santa Fe" },
    { "id": "21", "name": "SES", "description": "Santiago del Estero" },
    { "id": "22", "name": "TFU", "description": "Tierra del Fuego" },
    { "id": "23", "name": "TUC", "description": "Tucumán" }
  ];  

  projectOptions = [
    {
      id: this.newProjectOption,
      title: this.translateService.instant('constructive-feasibility-new-project.title')
    },
    {
      id: this.existingProjectOption,
      title: this.translateService.instant('constructive-feasibility-existing-project.title')
    },
  ];

  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;


  constructor(
    private appRoutingService: AppRoutingService,
    private shapeService: ShapeService,
    private translateService: TranslateService,
    private credentialsService: CredentialsService,
    private dataSourceService: DataSourceService,
    private platformService: PlatformService,
    private toastr: ToastrService
  ) { }

  async ngOnInit(): Promise<void> {
    this.project = this.projectOptions[0].id;
    this.element_datasource = this.dataSourceService.projectsDatasource;
    await this.getCurrentUserID();
    await this.getLastUpdate();
  }

  openFileUploaderPopup() {
    this.fileUploaderPopupVisible = !this.fileUploaderPopupVisible;
  }

  openCommentSectionPopup() {
    this.commentSectionPopupVisible = !this.commentSectionPopupVisible;
  }

  navigateTo(data?: any) {
    let url;
    const shapeID = data?.key?.shapeID;
    url = this.appRoutingService.generateUrl(this.path);
    if (shapeID) {
      url = url + `/${shapeID}`
    }
    this.appRoutingService.navigateTo(url, this.openNewTab);
  }

  onFileSelected(event: any) {
    const file = event.value[0];
    if (file) {
      const fileName = file.name;
      const isValid = this.validateFileExtension(fileName);

      if (isValid) {
        this.file = file;
      } else {
        this.resetForm();
        const errorMessage = 'common-odf.invalid.extension';
        this.showTemporaryErrorToastr(errorMessage);
        console.error(errorMessage);
      }
    }
  }


  validateFileExtension(fileName: string): boolean {
    const doubleExtensionPattern = /(\.[a-zA-Z0-9]+)\.[a-zA-Z0-9]+$/;
    return !doubleExtensionPattern.test(fileName);
  }

  resetForm() {
    this.file = null;
    this.fileUploader.instance.reset();
  }

  async handleZipUpload() {
    this.fileUploaderPopupVisible = false;
    this.loading = true;
    const zip = this.file;
    await this.processZipContents(zip);
  }

  async processZipContents(file: File) {
    try {
      this.loading = true;
      const zipContent = await this.loadZipContent(file);
      const foundFolders = this.extractSubfoldersFromZip(zipContent);
  
      await this.validateRequiredFolders(foundFolders);
      await this.validateRequiredFiles(zipContent, foundFolders);
  
      const allFiles = await Promise.all(
        Array.from(foundFolders).map(folder => this.getFilesInFolder(zipContent, folder))
      );
  
      const flattenedFiles = allFiles.flat();
      await this.storeShapefilesInDatabase(flattenedFiles);
    } catch (error) {
      console.error('Error processing the ZIP file:', error);
      this.loading = false;
      this.toastr.error("Ocurrió un error al subir el archivo.");
      this.resetProgress();
    }
  }

async storeShapefilesInDatabase(files: File[]) {
  if (!this.validateProject()) return;

  let taskIDFlag = this.taskId || this.generateUUID();
  const pollingSubject = new Subject<void>();

  try {
      this.processingProgress = 0;

      const request$ = this.isAnewProjectImport()
          ? this.handleNewProjectImport(files, taskIDFlag, pollingSubject)
          : this.handleExistingProjectImport(files, taskIDFlag, pollingSubject);

      const progressPolling$ = this.startProgressPolling(taskIDFlag, pollingSubject);

      await firstValueFrom(forkJoin([request$, progressPolling$]));

      setTimeout(() => {
        this.loading = false;
        this.showPersistentSuccessNotificationToastr(this.FILES_SUCCESSFULLY_UPLOADED);
        this.fileUploaderPopupVisible = false;
      }, 5000);
      
  } catch (error) {
      this.stopPolling(pollingSubject);
  }
}

private validateProject(): boolean {
  if (this.isProjectNameEmpty(this.newProjectName) && this.isAnewProjectImport()) {
      this.showTemporaryErrorToastr('constructive-feasibility-project-name.error');
      return false;
  }
  return true;
}

private handleNewProjectImport(files: File[], taskIDFlag: string, pollingSubject: Subject<void>): Observable<any> {
  return this.shapeService.createNewProject(
      files, this.currentUserID, this.newProjectName, this.selectedProjectType.toUpperCase(), taskIDFlag
  ).pipe(
      catchError(error => this.handleRequestError(error, pollingSubject))
  );
}

private handleExistingProjectImport(files: File[], taskIDFlag: string, pollingSubject: Subject<void>): Observable<any> {
  return this.shapeService.updateExistingProject(
      files, this.currentUserID, this.selectedProject?.projectID, this.selectedProjectType.toUpperCase(), taskIDFlag
  ).pipe(
      catchError(error => this.handleRequestError(error, pollingSubject))
  );
}

private startProgressPolling(taskId: string, pollingSubject: Subject<void>): Observable<number> {
  return timer(5000).pipe(
      switchMap(() => interval(1000).pipe(
          takeUntil(pollingSubject),
          switchMap(() => this.shapeService.getProgress(taskId).pipe(
              catchError(error => {
                  this.stopPolling(pollingSubject);
                  return of({ progress: this.processingProgress });
              })
          )),
          tap(res => {
              this.processingProgress = res.progress;
          }),
          takeWhile(res => res.progress < 100, true),
          map(res => res.progress)
      ))
  );
}

private stopPolling(pollingSubject: Subject<void>): void {
  pollingSubject.next();
  pollingSubject.complete();
}

private handleRequestError(error: any, pollingSubject?: Subject<void>): Observable<never> {
  console.error("Error en la solicitud:", error);
  if (pollingSubject) this.stopPolling(pollingSubject);
  this.loading = false;

  let errorCode = error?.error?.errorCode || 'UNKNOWN_ERROR';
  let errorMessage = error?.error?.message || 'UNKNOWN_ERROR';

  errorCode = this.isAnInvalidModelError(errorCode)
      ? `${this.translateErrorCode(errorCode)}: ${errorMessage}`
      : this.translateErrorCode(errorCode);

  this.showPersistentErrorToastr(errorCode);

  return of();
}

  generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  
  private resetProgress() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
    this.uploadProgress = 0;
    this.processingProgress = 0;
  }
  private async loadZipContent(file: File): Promise<JSZip> {
    const zip = new JSZip();
    try {
      const fileData = await file.arrayBuffer();
      return await zip.loadAsync(fileData);
    } catch (error) {
      console.error('Error loading and decompressing the ZIP file:', error);
      throw error;
    }
  }

  private extractSubfoldersFromZip(zipContent: JSZip): Set<string> {
    const subfolders: Set<string> = new Set();

    try {
      Object.keys(zipContent.files).forEach(fileName => {
        const file = zipContent.files[fileName];

        if (file.dir) {
          const folderPath = fileName.endsWith('/') ? fileName.slice(0, -1) : fileName;
          const parentFolder = folderPath.includes('/') ? folderPath.split('/').slice(0, -1).join('/') : null;

          if (parentFolder) {
            subfolders.add(folderPath);
          }
        }
      });

    } catch (error) {

      const errorMessage = `${this.translateService.instant('constructive-feasibility-zip-extract.error')}:', ${error}`

      this.showTemporaryErrorToastr(errorMessage);

      this.resetForm();
    }

    return subfolders;
  }

  private validateRequiredFolders(foundFolders: Set<string>): void {
    try {
      const requiredFolders = [Cons._TRACE, Cons._POINT, Cons._DUCT_STRAND];

      const matchedFolders = this.getExactRequiredFolders(requiredFolders, foundFolders);

      if (matchedFolders.length === 0) {
        this.handleNoValidFolders();
      }
    } catch (error) {
      this.handleValidatingRequiredError(error);
    }
  }

  private getExactRequiredFolders(requiredFolders: string[], foundFolders: Set<string>): string[] {
    return requiredFolders.filter(requiredFolder =>
      Array.from(foundFolders).some(foundFolder =>
        foundFolder.endsWith(requiredFolder) && foundFolder.split('/').pop() === requiredFolder
      )
    );
  }

  private handleNoValidFolders(): void {

    const errorMessage = this.translateService.instant('constructive-feasibility-no-valid-subfolders-found.error');

    this.showTemporaryErrorToastr(errorMessage);

    this.resetForm();

    throw new Error(errorMessage);
  }

  private handleValidatingRequiredError(error: any): void {
    console.error('Error validating required folders:', error);
    throw error;
  }

  private validateRequiredFiles(zipContent: JSZip, foundFolders: Set<string>) {
    const requiredExtensions = ['.dbf', '.shp', '.shx'];

    foundFolders.forEach(folder => {
      const filesInFolder = Object.keys(zipContent.files).filter(fileName => fileName.startsWith(folder));

      const missingFiles: string[] = [];
      requiredExtensions.forEach(ext => {
        const fileExists = filesInFolder.some(fileName => fileName.endsWith(ext));
        if (!fileExists) {
          missingFiles.push(ext);
        }
      });

      if (missingFiles.length > 0) {

        const errorMessage = `${this.translateService.instant('constructive-feasibility-missing-files-in-folder.error')} '${folder}': ${missingFiles.join(', ')}`;

        this.showTemporaryErrorToastr(errorMessage);

        this.resetForm();
        console.error(errorMessage);
        throw new Error(errorMessage);
      }
    });
  }

  private async getFilesInFolder(zipContent: JSZip, folder: string): Promise<File[]> {
    const filesInFolder: File[] = [];

    // Wait for all promises to resolve
    const promises = Object.keys(zipContent.files).map(async (fileName) => {
      if (fileName.startsWith(folder) && fileName.includes('.') && !fileName.endsWith('.')) {
        // Only process files with an extension
        const file = zipContent.files[fileName];
        const fileBlob = await file.async('blob');

        // Extract just the file name (remove the folder path)
        const baseName = fileName.substring(fileName.lastIndexOf('/') + 1);

        // Create the File object with the extracted baseName
        const fileObj = new File([fileBlob], baseName);
        filesInFolder.push(fileObj);
      }
    });

    // Wait for all files to be processed before returning the result
    await Promise.all(promises);

    return filesInFolder;
  }


 


  getPopupTitle(): string {
    const country = this.countries.find(c => c.id === this.selectedCountry);
    const province = this.provinces.find(p => p.id === this.selectedProvince);
    const projectName = this.projectName || '';

    this.newProjectName = `${country?.name || ''}-${province?.name || ''}-${projectName}`;

    return this.newProjectName;
  }

  async getCurrentUserID() {
    const currentUserIDstring = this.credentialsService.decodeToken()?.['userID'];
    this.currentUserID = currentUserIDstring ? parseInt(currentUserIDstring, 10) : undefined;
    return this.currentUserID;
  }

  async getLastUpdate(): Promise<void> {
    const platformID = this.platformService.getActivePlatform();
    const response = await firstValueFrom(this.shapeService.getLastUpdates())
    this.lastUpdatesShapes = response.responseData;
  }

  private isProjectNameEmpty(projectName: string): boolean {
    return !projectName || projectName === '--';
  }

  private showPersistentErrorToastr(errorCode: string) {

    errorCode = errorCode.replace(/\r?\n/g, '<br>');

    this.toastr.error(errorCode, '', {
        timeOut: 0,
        extendedTimeOut: 0,
        tapToDismiss: true,
        enableHtml: true
    });
}

  private showTemporaryErrorToastr(errorCode: string) {
    this.toastr.error(this.translateService.instant(errorCode), '', {
      timeOut: 7000,
      extendedTimeOut: 0,
      tapToDismiss: true,
      progressBar: true,
      progressAnimation: 'decreasing'
    });
  }

  private showPersistentSuccessNotificationToastr(message: string){
    this.toastr.success(this.translateService.instant(message), '', {
      timeOut: 0,
      extendedTimeOut: 0,
      tapToDismiss: true
    });
  }

  isAnInvalidModelError(errorCode: string): boolean {
    return [
      this.INVALID_CONTAINER_MODEL,
      this.INVALID_INFRASTRUCTURE_MODEL,
      this.INVALID_DEVICE_MODEL,
      this.INVALID_TRACE_MODEL
    ].includes(errorCode);
  }

  private translateErrorCode(errorCode: string): string {
    return this.translateService.instant(errorCode);
  }

  private isAnewProjectImport(): boolean {
    const isAnewProjectImport = this.project === this.newProjectOption;
    return isAnewProjectImport;
  }

}