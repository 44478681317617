import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MapSourceService } from '@app/@shared/services/map-elements-services/map-source.service';
import { PlatformService } from '@app/auth/platform.service';
import { OlMapComponent } from '@app/@shared/components/ol-map/ol-map.component';
import { filter, firstValueFrom, tap } from 'rxjs';
import WKT from 'ol/format/WKT';
import { ChangeDetectorRef } from '@angular/core';
import { LocationData } from '@app/@shared/model/aux-models/locationData';
import { olMapLineString } from '@app/@shared/model/aux-models/ol-map-models/olMapLineString';
import { ShapeService } from '@app/@shared/services/shape.service';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import CustomStore from 'devextreme/data/custom_store';
import { ProjectQueryViewResponse } from '@app/@shared/model/interface/project-query-view-response';
import { Cons } from '@app/@shared/cons/cons';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-constructive-feasibility-map',
  templateUrl: './constructive-feasibility-map.component.html',
  styleUrls: ['./constructive-feasibility-map.component.scss']
})
export class ConstructiveFeasibilityMapComponent implements OnInit, AfterViewInit {

  @ViewChild('olMap') olMap: OlMapComponent;


  latitude: number = -34.604369;
  longitude: number = -58.3850703;
  zoom: number = 8;
  drawerOpened: boolean = false;
  showOverflow: boolean = true;
  isSearchOpen = true;
  mapButtonClicked = false;
  dowloadImg = false;
  eraserActive = false;
  shareIcon = "../../../../../../../assets/icons/sharedViewIcon.png";
  traceIcon = "../../../../../../../assets/icons/sitemap-icon.png";
  selectedOption: any;
  accordion_search_selected = [];
  map: any;
  selectedMapOption: string;
  xygoMapSelected: boolean = false;
  googleMapSelected: boolean;
  openStreetMapSelected: boolean;
  points: any[];
  lineStrings: any[];
  multiLineStrings: any[];
  element_datasource: CustomStore;
  selectedProject: ProjectQueryViewResponse = null;

  ds_accordion_search = [
    { title: 'Panel de búsqueda de proyecto' }
  ];

  ds_accordion_checkbox = [
    { title: 'Selecciona opciones' }
  ];

  checkboxOptions = [];
  

  accordion_checkbox_selected = [];
  mapOptions = [{ name: 'Xygo' }, { name: 'Google' }, { name: 'Open Street' }];
  subscription: any;
  shapeID: number;


  constructor(
    private route: ActivatedRoute,
    private mapSourceService: MapSourceService,
    private platformService: PlatformService,
    private shapeService: ShapeService,
    private cdr: ChangeDetectorRef,
    private dataSourceService: DataSourceService) { }

  async ngOnInit(): Promise<void> {
    this.element_datasource = this.dataSourceService.projectsDatasource;
    this.selectedMapOption = this.mapOptions[0].name;
    let initialPos = this.platformService.getPlatformPreference("initialPosition");
    if (initialPos) {
      this.latitude = initialPos.lat;
      this.longitude = initialPos.lon;
      this.zoom = initialPos.zoom;
    }
    if (initialPos) {
      this.latitude = initialPos.lat;
      this.longitude = initialPos.lon;
      this.zoom = initialPos.zoom;
    }
    else {
      this.getUserPosition().then(({ lon, lat, zoom }) => {
        this.latitude = lat;
        this.longitude = lon;
        this.zoom = zoom;
      },
        ((err) => {
          alert(err);
          this.latitude = -34;
          this.longitude = -58;
          this.zoom = 6;
        }));
    }

    const shapeIDString = this.route.snapshot.paramMap.get('shapeID');
    if (shapeIDString) {
      this.shapeID = +shapeIDString;
    }
    console.log('this.shapeID: ', this.shapeID);

  }

  async ngAfterViewInit(): Promise<void> {
    if (this.olMap && this.shapeID) {
      await this.drawResponse(this.shapeID);
    } else {
      console.error("OlMapComponent no está disponible en ngAfterViewInit");
    }
  }



  toggleDrawer() {
    this.drawerOpened = !this.drawerOpened;
  }

  onMapReady(map: any) {
    this.map = map;
  }

  downloadMap(): void {
    const size = this.map.getSize();
    const pixelRatio = window.devicePixelRatio || 1;
    const mapCanvas = this.createMapCanvas(size, pixelRatio);

    const mapContext = mapCanvas.getContext('2d');
    if (!mapContext) {
      console.error('Could not get the canvas context.');
      return;
    }

    mapContext.scale(pixelRatio, pixelRatio);

    this.renderMapLayersToCanvas(mapContext, mapCanvas);

    this.map.once('rendercomplete', () => {
      this.downloadCanvasAsImage(mapCanvas);
    });

    this.map.renderSync();
  }

  createMapCanvas(size: [number, number], pixelRatio: number): HTMLCanvasElement {
    const mapCanvas = document.createElement('canvas');
    mapCanvas.width = size[0] * pixelRatio;
    mapCanvas.height = size[1] * pixelRatio;
    mapCanvas.style.width = `${size[0]}px`;
    mapCanvas.style.height = `${size[1]}px`;
    return mapCanvas;
  }

  renderMapLayersToCanvas(mapContext: CanvasRenderingContext2D, mapCanvas: HTMLCanvasElement): void {
    const mapLayers = document.querySelectorAll('.ol-layer canvas');
    mapLayers.forEach((canvas) => {
      if (canvas instanceof HTMLCanvasElement && canvas.width > 0) {
        const transform = canvas.style.transform;
        const matrix = transform
          .match(/^matrix\(([^\)]+)\)$/)?.[1]
          .split(',')
          .map(Number);

        if (matrix) {
          mapContext.setTransform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
        } else {
          mapContext.setTransform(1, 0, 0, 1, 0, 0);
        }

        mapContext.drawImage(canvas, 0, 0);
      }
    });
  }

  downloadCanvasAsImage(mapCanvas: HTMLCanvasElement): void {
    mapCanvas.toBlob((blob) => {
      if (blob) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'map.png';
        link.click();
        URL.revokeObjectURL(link.href);
      }
    }, 'image/png');
  }

  selectMapOption(event: any, optionName: string) {
    if (optionName == Cons._XYGOTYPE) {
      this.xygoMapSelected = true;
      this.googleMapSelected = false;
      this.openStreetMapSelected = false;

    } else if (optionName == Cons._GOOGLETYPE) {
      this.xygoMapSelected = false;
      this.googleMapSelected = true;
      this.openStreetMapSelected = false;

    } else if (optionName == Cons._OPENSTREETTYPE) {
      this.xygoMapSelected = false;
      this.googleMapSelected = false;
      this.openStreetMapSelected = true;
    }
  }

  selectMap() {
    this.selectedMapOption = this.mapOptions[this.mapSourceService.mapSource].name;
    this.mapButtonClicked = !this.mapButtonClicked;
  }

  getUserPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp =>
        resolve({ lon: resp.coords.longitude, lat: resp.coords.latitude, zoom: 8 }),
        err => {
          //Geolocation didn't work. Set some harcoded position
          return resolve({ lon: -58.3850703, lat: -34.604369, zoom: 4 })
        }
      );
    });
  }


  async drawResponse(shapeID: number) {

    try {
      const response = await firstValueFrom(this.shapeService.getByID(shapeID));
      const elements = response.features;

      this.points = [];
      this.lineStrings = [];
      this.multiLineStrings = [];
     
      for (const element of elements) {
        this.processResponse(element);
      }


      console.log('this.points', this.points);
      console.log('this.lineStrings', this.lineStrings);
      console.log('this.multiLineStrings', this.multiLineStrings);

      // this.cdr.detectChanges();
      // this.olMap.renderPoints();
      // this.olMap.renderLineString();
      //  this.mapViewerComponent.drawLinesAndPoints(this.lineStrings);


    } catch (error) {
      console.error('Error while drawing response:', error);
    }
    // this.olMap.renderPoints();
    // this.olMap.renderLineString();
  }

  processResponse(response: any) {
    try {
      let flatCoordinates;
      const wkt = response?.geometry?.wkt;

      if (!wkt) {
        throw new Error('WKT is undefined');
      }

      const coordinates = this.readWktGeometry(wkt);
      flatCoordinates = coordinates.flatCoordinates;

      if (!coordinates) {
        throw new Error('Invalid coordinates from WKT');
      }

      const geometryType = this.getGeometryTypeFromWkt(wkt);

      if (!geometryType) {
        throw new Error('Invalid geometry type from WKT');
      }

      switch (geometryType) {
        case 'POINT':
          this.points.push(this.createOlPoint(response, flatCoordinates));
          break;

        case 'LINESTRING':
          this.lineStrings.push(this.createOlLineString(response, flatCoordinates));
          break;

        case 'MULTILINESTRING':
          this.multiLineStrings.push(
            this.createOlMultiLineString(response, flatCoordinates)
          );
          break;

        default:
          console.warn('Unsupported geometry type:', geometryType);
          break;
      }
    } catch (error) {
      console.error('Error processing response:', error);
    }
  }



  private idCounter = 0;  // Añade esta variable como propiedad de la clase

  createOlPoint(point: any, coordinates: number[]): any {
    // Incrementar el contador para generar un ID único
    const uniqueId = `point_${++this.idCounter}`;
  
    // Determinar el icono según el modelo
    let iconPath = 'markers/device.png';
    if (point.properties && point.properties.model && point.properties.model.includes('POSTE')) {
      iconPath = 'markers/purple_octagon.png';
    }
  
    const pointEntity = {
      id: uniqueId,
      name: point.name || 'unknown_name',
      type: 'Point',
      coordinatesEPSG4326: [...coordinates],
      entityName: 'Point',
      commonID: uniqueId,
    };
  
    return {
      id: uniqueId,
      color: point.color || 'rgb(0, 128, 255)',
      icon: iconPath,
      flatCoordinates: coordinates,
      visible: true,
      selected: false,
      name: point.name || 'unknown_name',
      type: 'Point',
      entity: pointEntity,
      popupEnable: true,
    };
  }

  private createOlLineString(lineString: any, coordinates: number[]): any {
    const uniqueId = ++this.idCounter;

    // Convertir las coordenadas planas a pares de coordenadas
    const formattedCoordinates = [];
    for (let i = 0; i < coordinates.length; i += 2) {
      formattedCoordinates.push([coordinates[i], coordinates[i + 1]]);
    }

    // Crear el WKT con las coordenadas formateadas
    const locationData = {
      inputSrID: 4326,
      outputSrID: 4326,
      coordinates: {
        geographyType: "LineString",
        values: `LINESTRING (${formattedCoordinates.map(coord =>
          coord.join(' ')).join(', ')})`
      },
      format: "WKT"
    };

    const elementType = {
      specAttributes: {
        showFiber: true,
        bufferLength: 4,
        color: "blue",
        fiberData: []
      },
      elementTypeID: 1463,
      platformID: 11,
      name: "4-48 FO KP",
      reference: "4-48 FO KP",
      groupID: "TRACE",
      filePath: null
    };

    const modelType = {
      specAttributes: {},
      elementTypeID: 0,
      platformID: 1,
      name: "ElementType Test Updated",
      reference: "",
      groupID: "",
      filePath: ""
    };

    const entity = {
      locationData: locationData,
      sectionAttributes: {},
      entitySignature: null,
      __modified: null,
      traceID: uniqueId,
      platformID: 11,
      elementTypeID: 1463,
      modelTypeID: 0,
      containerID: 0,
      name: lineString.name || "TEST",
      description: "200",
      length: 151.06,
      sectionData: null,
      aud_User: 100,
      aud_Date: new Date().toISOString(),
      aud_Action: "I",
      elementType: elementType,
      modelType: modelType,
      fiber: [],
      commonID: uniqueId,
      entityName: "TRACE"
    };

    return {
      visible: true,
      selected: false,
      entity: entity,
      id: `TRACE_${uniqueId}`,
      name: lineString.name || "TEST",
      type: "1463",
      color: "blue",
      width: 2,
      locationData: locationData,
      flatCoordinates: formattedCoordinates,
      elementTypeID: 1463,

    };
  }












  private createOlMultiLineString(multiLineString: any, coordinatesArray: number[][][]): any {
    // Intercambiar latitud y longitud en cada coordenada
    const swappedCoordinatesArray = coordinatesArray.map(line => line.map(coord => [coord[1], coord[0]]));

    const multiLineStringEntity = {
      id: multiLineString.id || 'unknown_id',
      name: multiLineString.name || 'unknown_name',
      type: 'MultiLineString',
      coordinatesEPSG4326: [...coordinatesArray],
      entityName: 'MultiLineString',
      commonID: multiLineString.id,
    };

    return {
      id: multiLineString.id || 'unknown_id',
      color: multiLineString.color || 'rgb(255, 69, 0)',
      width: 2,
      flatCoordinates: coordinatesArray,
      visible: true,
      selected: false,
      name: multiLineString.name || 'unknown_name',
      type: 'MultiLineString',
      entity: multiLineStringEntity,
      popupEnable: true,
    };
  }



  readWktGeometry(wkt: string): any {
    const wktFormat = new WKT();
    return wktFormat.readGeometry(wkt);
  }

  private getGeometryTypeFromWkt(wkt: string): string | null {
    const match = wkt.match(/^\s*(\w+)/);
    return match ? match[1].toUpperCase() : null;
  }


  async search() {
    console.log(this.selectedProject);
    const projectID = this.selectedProject.projectID;
    const response = await firstValueFrom(this.shapeService.getAllShapesByProjectID(projectID));
    console.log('response: ', response);
    this.transformSearchResponseToCheckBox(response);
  }

  async drawShape() {
    const selectedOptions = this.getSelectedOptions().flatMap(group => group.selectedOptions);
    console.log('Selected options:', selectedOptions);

    for(let i = 0; i < selectedOptions.length; i++){
      const shapeID = selectedOptions[i].shapeID;

      await this.drawResponse(shapeID);
    }
  }
  
  getSelectedOptions(): any[] {
    return this.checkboxOptions
      .filter(group => group.selected || group.subOptions.some(option => option.selected))
      .map(group => ({
        entityShape: group.label,
        selectedOptions: group.subOptions.filter(option => option.selected)
      }));
  }

  transformSearchResponseToCheckBox(response: any) {
    const entityGroups: { [key: string]: any } = {};
  
    response.forEach((shape: any) => {
      const entityShape = shape.shapeData.entityShape; 
      const version = `Version ${shape.shapeData.version}`; 
      const shapeID = shape.shapeData.shapeID;
  
      if (!entityGroups[entityShape]) {
        entityGroups[entityShape] = {
          label: entityShape,
          selected: false,
          subOptions: []
        };
      }
  
      entityGroups[entityShape].subOptions.push({
        label: version,
        shapeID: shapeID,
        selected: false
      });
    });
  
    this.checkboxOptions = Object.values(entityGroups);
    console.log('Checkbox options:', this.checkboxOptions);
  }
  

  updateParentCheckbox(option: any) {
    const checkedCount = option.subOptions.filter((sub: any) => sub.selected).length;

    if (checkedCount > 0) {
      option.selected = true;
      option.indeterminate = checkedCount < option.subOptions.length;
    } else {
      option.selected = false;
      option.indeterminate = false;
    }
  }

  toggleAllSubOptions(option: any) {
    const isChecked = option.selected;

    option.subOptions.forEach((sub: any) => {
      sub.selected = isChecked;
    });
  }
}
