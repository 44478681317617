import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CircularHierarchyTreeComponent } from '@app/@shared/components/d3/circular-hierarchy-tree/circular-hierarchy-tree.component';
import { D3CircularHierarchyTreeData } from '@app/@shared/model/d3/circular-hierarchy-tree.model';
import { Device } from '@app/@shared/model/device.model';
import { GridAndCircularTree } from '@app/@shared/model/device-box/d3-circular-tree.model';
import { SelectedDevice } from '@app/@shared/model/device-box/selected-device.model';
import { IRedirectSettings } from '@app/@shared/model/interface/iRedirect-settings';
import { WindowService } from '@app/@shared/services/cordova/window.service';
import { DeviceService } from '@app/@shared/services/map-elements-services/device.service';
import { firstValueFrom } from 'rxjs';
import { ContainerService } from '@app/@shared/services/map-elements-services/container.service';
import { Container } from '@app/@shared/model/container.model';
import { take } from 'rxjs';
import { HTMLtoSVGDownlaodService } from '@app/@shared/services/d3/html-to-svg-download.service';
import { TranslateService } from '@ngx-translate/core';
import { Console } from 'console';
import { ChangeDetectorRef } from '@angular/core';
import { IntersectionObserverService } from '@app/@shared/services/intersection-observer.service';

@Component({
  selector: 'device-box',
  templateUrl: './device-box.component.html',
  styleUrls: ['./device-box.component.scss'],
})

export class DeviceBoxComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(CircularHierarchyTreeComponent) circularHierarchyTreeComponents: QueryList<CircularHierarchyTreeComponent>;
  @ViewChild('content') content: ElementRef;
  @ViewChildren('fiberDiagram') fiberDiagrams: QueryList<ElementRef>;

  /*
      This component can be rendered via URL, in that case we'll get the data from the resolvers inside the activatedRoute.
      But if this component is rendered inside HTML using the <device-box> selector, we need to pass this Input to supply the missing information.
  */
  @Input() devices?: Device[];
  @Output() componentReady = new EventEmitter<void>();

  traceNameInFiberDiagram: any[] = [];
  concatenatedTraceNames: { traceNames: string, index: number }[] = [];
  selectedTraceConnection: { traceNames: string; index: number };
  selectedTraceConnectionIndex: number;

  loading: boolean = false;
  deviceID: number;
  selectedDevices: SelectedDevice[] = [];
  selectedDevicesID: any[] = [];
  noDevicesFound = false;
  selectedTab: number = 0;
  getFiberName: any[] = [];

  searchTerm: string;

  match: HTMLElement[] = [];
  currentMatchIndex: number = -1;

  selectedDevicesB: SelectedDevice[] = [];
  devicesFromContainerByMslink: Device[];
  observer: IntersectionObserver;



  constructor(
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceService,
    private containerService: ContainerService,
    private windowService: WindowService,
    private router: Router,
    private htmlToSVGDownlaodService: HTMLtoSVGDownlaodService,
    private trans: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private intersectionObserverService: IntersectionObserverService
  ) { }

  ngOnInit() {
    const tokenFromActivatedRoute: string = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (this.activatedRoute.snapshot.params["containerID"]) {
      this.resolveFromContainerMslink();
    }
    else {
      if (tokenFromActivatedRoute) {
        this.resolveFromDeviceMslink();
      }
      else {
        this.resolveFromDeviceID();
      }
    }

    const selectedTabFromURL = this.activatedRoute.snapshot.params['selectedTab'];
    if (selectedTabFromURL) {
      this.selectedTab = +selectedTabFromURL;
    } else {
      this.selectedTab = 0;
    }
  }



  ngAfterViewInit(): void {
    this.fiberDiagrams.changes.subscribe((diagrams: QueryList<ElementRef>) => {
      this.observeFiberDiagrams(diagrams);
    });
  }

  ngOnDestroy(): void {
    this.intersectionObserverService.disconnect();
  }

  resolveFromContainerMslink() {
    const mslinkFromActivatedRoute: string = this.activatedRoute.snapshot.paramMap.get('containerID');

    this.activatedRoute.data.subscribe(async ({ deviceBox }: { deviceBox: D3CircularHierarchyTreeData[][] }) => {

      if (mslinkFromActivatedRoute) {
        this.containerService.getByMslink(mslinkFromActivatedRoute).subscribe((c: Container) => {
          if (c.device && c.device.length > 0) {
            if (c.device.length > 1) {
              //Assign all the devices but one to the tag list (the first is the one that is going to be displayed)
              this.devices = c.device?.slice(1);
              this.devicesFromContainerByMslink = c.device;
            }
            else {
              this.devices = c.device;
            }
            this.selectedDevices = [this.generateSelectedDevices(c.device[0], deviceBox)];
            this.deviceID = c.device[0].deviceID
            this.selectedDevicesID = this.selectedDevices.map(x => x.device.deviceID);
          }
          else {
            this.noDevicesFound = true;
          }
        });
      }
    });
  }

  resolveFromDeviceMslink() {
    const mslinkFromActivatedRoute: string = this.activatedRoute.snapshot.paramMap.get('deviceID');

    this.activatedRoute.data.subscribe(async ({ deviceBox, devices }: { deviceBox: D3CircularHierarchyTreeData[][], devices: Device[] }) => {

      if (mslinkFromActivatedRoute) {
        devices = [];
        this.deviceService.getByMslink(mslinkFromActivatedRoute).subscribe((res: Device) => {

          if (res.containerID && res.containerID != 0) {
            this.containerService.getByID(res.containerID).subscribe((c: Container) => {
              this.devices = c.device?.filter(x => x.deviceID != res.deviceID);;
              this.selectedDevices = [this.generateSelectedDevices(res, deviceBox)];
            });
          }
          else {
            this.devices = []
            this.selectedDevices = [this.generateSelectedDevices(res, deviceBox)];
          }


          this.deviceID = res.deviceID
          this.selectedDevicesID = this.selectedDevices.map(x => x.device.deviceID);
        });
      }

    });
  }

  resolveFromDeviceID() {
    const deviceIDFromActivatedRoute: string = this.activatedRoute.snapshot.paramMap.get('deviceID');

    if (deviceIDFromActivatedRoute) {
      this.deviceID = Number(this.activatedRoute.snapshot.paramMap.get('deviceID'));
    } else if (this.devices?.length === 1) {
      // If this component is rendered via HTML (not via url), we'll get a single device in the devices array.
      this.deviceID = this.devices[0].deviceID;
    }

    this.activatedRoute.data.subscribe(async ({ deviceBox, devices }: { deviceBox: D3CircularHierarchyTreeData[][], devices: Device[] }) => {

      // If this component is rendered via HTML (not via url), we don't get this data from the Resolver.
      if (!devices && !deviceBox) {
        this.selectedDevices = this.selectedDevicesB = [await this.getDeviceBoxesAndGenerateSelectedDevices(this.devices[0])];
      } else {
        const initialDevice = devices[0];
        if (initialDevice.containerID && initialDevice.containerID != 0) {
          this.containerService.getByID(initialDevice.containerID).subscribe((c: Container) => {
            this.devices = c.device?.filter(x => x.deviceID != initialDevice.deviceID);
            this.selectedDevices = this.selectedDevicesB = c.device?.filter(x => x.deviceID === this.deviceID).map(x => this.generateSelectedDevices(x, deviceBox));
          });
        }
        else {
          this.devices = devices.filter(x => x.deviceID != initialDevice.deviceID);
          this.selectedDevices = this.selectedDevicesB = devices?.filter(x => x.deviceID === this.deviceID).map(x => this.generateSelectedDevices(x, deviceBox));
        }
      }

      this.selectedDevicesID = this.selectedDevices.map(x => x.device.deviceID);
    });
  }

  async deviceChange(value: number) {
    // this.selectedDevicesID = value;
    this.selectedDevicesID = this.devices;
    this.selectedDevicesID.push(value);
    this.selectedDevices = this.selectedDevices.filter(x => this.selectedDevicesID.includes(x.device.deviceID));
    console.log("selected devices id-->", this.selectedDevicesID);
    const newDevices: Device[] = this.selectedDevicesID.filter(deviceID => !this.selectedDevices.map(x => x.device.deviceID).includes(deviceID)).map(deviceID => this.devices.find(y => y.deviceID === deviceID)).filter(x => !!x);
    const promises = newDevices.map((x) => this.getDeviceBoxesAndGenerateSelectedDevices(x));

    let newSelectedDevices: SelectedDevice[] = [];
    // this.loading = true;

    try {
      newSelectedDevices = await Promise.all(promises);
    } catch (ex) {
      console.error('ex', ex);
      return;
    } finally {
      // this.loading = false;
    }

    this.devices.pop();

    this.selectedDevices = [
      ...this.selectedDevices,
      ...newSelectedDevices,
    ].sort((a, b) => a.device.deviceID > b.device.deviceID ? 1 : -1);

    console.log("selected devices-->", this.selectedDevices);
    if (this.selectedDevices.length > 0)
      await this.downloadGraph(this.selectedDevices[0]);
  }

  async getDeviceBoxesAndGenerateSelectedDevices(device: Device): Promise<SelectedDevice> {
    const deviceBox = await firstValueFrom(this.deviceService.getDeviceBox(device.deviceID));
    return this.generateSelectedDevices(device, deviceBox);
  }


  generateSelectedDevices(device: Device, deviceBox: D3CircularHierarchyTreeData[][]): SelectedDevice {
    const refConnection: { [key: string]: any } = {};

    const isConnected = (d3CircularHierarchyTreeData: D3CircularHierarchyTreeData, _: number, arr: D3CircularHierarchyTreeData[]) => {
      return !!d3CircularHierarchyTreeData.importAttr || arr.some(y => y.importAttr?.traceID === d3CircularHierarchyTreeData.mainAttr.traceID);
    }

    const distinct = (d3CircularHierarchyTreeData: D3CircularHierarchyTreeData, _: number, arr: D3CircularHierarchyTreeData[]) => {
      let key = `${d3CircularHierarchyTreeData.traceID}_${d3CircularHierarchyTreeData.fiberID}`;
      let importKey = d3CircularHierarchyTreeData.importAttr?.traceID ? `${d3CircularHierarchyTreeData.importAttr?.traceID}_${d3CircularHierarchyTreeData.importAttr.fiberID}` : undefined;

      let f1 = false;
      let f2 = false;

      if (!refConnection[key]) {
        refConnection[key] = 0;
        f1 = true;
      }

      if (importKey && !refConnection[importKey]) {
        refConnection[importKey] = 0;
        f2 = true;
      }

      return f1 || f2;
    }

    const fiberData: GridAndCircularTree[] = deviceBox.map((x, i) => {
      x.forEach(f => {
        f.name = f.traceID + "." + f.mainAttr.buffer + "." + f.mainAttr.color;
        if (f.importAttr) {
          f.imports = f.importAttr.traceID + "." + f.importAttr.buffer + "." + f.importAttr.color;
        }
      });
      const fibers = x;

      return ({
        id: deviceBox.length > 1 ? `${device.name}-${i + 1}` : `${device.name}`,
        fibers,
        gridFibers: fibers.filter(distinct).filter(isConnected),
        selectedFibers: [],
        hideUnselectedFibers: false
      })
    });

    return { device, fiberData };
  }

  viewDeviceBoxV1(device: Device) {
    const mslink = device.deviceAttributes.mslink;
    const redirectSettings: IRedirectSettings = {
      path: `http://ollcomms01xpw:5559/ReporteFOImagenBox.aspx?caj=${mslink}`,
      newTab: true,
      external: true,
    };

    this.windowService.redirectTo(redirectSettings);
  }

  async downloadGraph(selectedDevice: SelectedDevice): Promise<void> {
    this.loading = true;

    // const ids = selectedDevice.fiberData.map(x => x.id);
    // const promises: Promise<void>[] = [];

    // if (selectedDevice.device.containerID != 0) {
    //   // Obtener el contenedor asociado al selectedDevice
    //   const container = await firstValueFrom(this.containerService.getByID(selectedDevice.device.containerID).pipe(take(1))); // Tomar solo el primer valor y luego completar la suscripción automáticamente
    // }

    // // Para cada componente en circularHierarchyTreeComponents, descargar el gráfico D3
    // promises.push(...this.circularHierarchyTreeComponents
    //   .filter(x => ids.includes(x.id))
    //   .map(x => x?.downloadD3Graph())
    // );

    // await Promise.all(promises);
    await this.htmlToSVGDownlaodService.downloadAllSVGs(selectedDevice.device, this.selectedTab);

    this.loading = false;

  }


  async downloadAllDevicesGraph() {
    this.loading = true;
    let allDevices;
    // let allDeviceIds = this.devices.map(device => device.deviceID);
    // let urlDeviceID = Number(this.activatedRoute.snapshot.paramMap.get('deviceID'));
    // if (urlDeviceID != 0)
    //   allDeviceIds.push(urlDeviceID);

    // await this.downloadGraph(this.selectedDevicesB[0]);

    // for (let i = 0; i < allDeviceIds.length; i++) {
    //   if (i == allDeviceIds.length - 1) {
    //     this.selectedDevices = this.selectedDevicesB;
    //   } else
    //     await this.deviceChange(allDeviceIds[i]);
    // }

    if (this.devicesFromContainerByMslink) {
      allDevices = this.devicesFromContainerByMslink;
    } else {
      allDevices = this.devices;
      let urlDeviceID = Number(this.activatedRoute.snapshot.paramMap.get('deviceID'));
      let actualDeviceSelected = await firstValueFrom(this.deviceService.getByID(urlDeviceID));
      allDevices.push(actualDeviceSelected);
    }


    for (const device of allDevices) {
      try {
        if (device) {
          await this.htmlToSVGDownlaodService.downloadAllSVGs(device, this.selectedTab);
        }
      } catch (error) {
        console.error(`Error processing device ${device.deviceID}:`, error);
      }
    }
    this.loading = false;
  }

  deviceReady(device: SelectedDevice, fiberData: GridAndCircularTree) {
    fiberData.rendered = true;

    if (device.fiberData.every(x => x.rendered)) {
      this.componentReady.emit();
    }
  }


  openDiagram(device: Device) {
    const token = this.activatedRoute.snapshot.queryParams['token'];

    let basePath = '';
    for (let i = 0; i < this.activatedRoute.snapshot.url.length - 1; i++) {
      let path = this.activatedRoute.snapshot.url[i].path;
      if (path.toLowerCase() == 'container') {
        //override when the access was through container
        path = 'device';
      }
      basePath += `/${path}`;
    }

    if (token) {
      basePath += `/${device.deviceAttributes.mslink}`;
      const url = this.router.serializeUrl(
        this.router.createUrlTree([basePath], { queryParams: { token: token } })
      );

      window.open(url, '_blank');
    }
    else {
      basePath += `/${device.deviceID}`;
      const url = this.router.serializeUrl(
        this.router.createUrlTree([basePath])
      );

      window.open(url, '_blank');
    }

  }

  async processInBatches<T>(items: T[], batchSize: number, processFunction: (item: T) => Promise<void>): Promise<void> {
    for (let i = 0; i < items.length; i += batchSize) {
      const batch = items.slice(i, i + batchSize);
      await Promise.all(batch.map(item => processFunction(item)));
    }
  }






  findFiberIndex(fiberID: string, children: any[]): number | string {
    const index = children.findIndex(child => child.fiberID === fiberID);
    return index !== -1 ? index + 1 : 'Not found';
  }



  handleTracesName(traceName: string) {

    this.traceNameInFiberDiagram.push(traceName);

    if (this.traceNameInFiberDiagram.length % 2 === 0) {
      const lastTwo = this.traceNameInFiberDiagram.slice(-2);
      const concatenated = {
        traceNames: `${lastTwo[0] + ' <--> ' + lastTwo[1]}`,
        index: this.concatenatedTraceNames.length
      };

      this.concatenatedTraceNames.push(concatenated);
    }
  }


  scrollToConnectionDiagram(event: any) {
    const selectBoxIndex = event.itemIndex;
    const fiberDiagramId = 'fiber-diagram-' + selectBoxIndex;
    const fiberDiagram = document.getElementById(fiberDiagramId);
    if (fiberDiagram) {
      fiberDiagram.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error('No se encontró el elemento');
    }
  }


  scrollToTop() {
    const topElement = document.getElementById('top');
    if (topElement) {
      topElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onTabChanged() {
    if (this.selectedTab == 1) {
      this.concatenatedTraceNames = [];
      this.traceNameInFiberDiagram = [];
      this.selectedTraceConnectionIndex = undefined;
    }

  }


  isFiberDiagramVisible(index: number): boolean {
    const fiberDiagramId = 'fiber-diagram-' + index;
    const fiberDiagram = document.getElementById(fiberDiagramId);

    if (fiberDiagram) {
      const rect = fiberDiagram.getBoundingClientRect();
      const viewHeight = window.innerHeight || document.documentElement.clientHeight;
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    return false;
  }

  highlightMatches() {
    const searchTerm = this.searchTerm.trim().toLowerCase();
    this.clearHighlights();

    if (searchTerm === '') return;

    const elements = this.content.nativeElement.querySelectorAll('svg *');
    this.match = [];

    elements.forEach((el: HTMLElement) => {
      if (el.childNodes.length === 1 && el.textContent) {
        const text = el.textContent.toLowerCase();
        if (text.includes(searchTerm)) {
          this.match.push(el);
        }
      }
    });

    this.currentMatchIndex = this.match.length ? 0 : -1;
    this.scrollToCurrentMatch();
  }

  clearHighlights() {
    this.match.forEach((el) => (el.style.backgroundColor = ''));
    this.match = [];
    this.currentMatchIndex = -1;
  }

  previousMatch() {
    if (this.match.length === 0) return;

    this.currentMatchIndex = (this.currentMatchIndex - 1 + this.match.length) % this.match.length;
    this.scrollToCurrentMatch();
  }

  nextMatch() {
    if (this.match.length === 0) return;

    this.currentMatchIndex = (this.currentMatchIndex + 1) % this.match.length;
    this.scrollToCurrentMatch();
  }

  scrollToCurrentMatch() {
    if (this.currentMatchIndex >= 0 && this.currentMatchIndex < this.match.length) {
      const currentMatch = this.match[this.currentMatchIndex];
      currentMatch.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  // Sets up observation for fiber diagrams
  // and updates the selectedTraceConnection when a diagram becomes visible
  private observeFiberDiagrams(diagrams: QueryList<ElementRef>): void {
    const elements = diagrams.map(diagram => diagram.nativeElement);
    this.intersectionObserverService.observeElements(elements, this.handleFiberDiagramIntersection.bind(this));
  }

  private handleFiberDiagramIntersection(entry: IntersectionObserverEntry): void {
    const id = entry.target.getAttribute('id');
    const index = id ? parseInt(id.replace('fiber-diagram-', ''), 10) : null;

    if (index !== null && index !== this.selectedTraceConnection?.index) {
      const traceConnectionFound = this.concatenatedTraceNames.find(item => item.index === index);
      if (traceConnectionFound) {
        this.selectedTraceConnection = traceConnectionFound;
        this.selectedTraceConnectionIndex = traceConnectionFound.index;
        this.changeDetectorRef.detectChanges();
      }
    }
  }



}
