import { Component, OnInit, ViewChild } from '@angular/core';
import { AppRoutingService } from '@app/app-routing.service';
import { DxFileUploaderComponent } from 'devextreme-angular';
import * as JSZip from 'jszip';
import { Cons } from '@app/@shared/cons/cons';
import { TranslateService } from '@ngx-translate/core';
import notify from 'devextreme/ui/notify';
import { first, firstValueFrom } from 'rxjs';
import { CredentialsService } from '@app/auth';
import { ShapeService } from '@app/@shared/services/shape.service';
import { DataSourceService } from '@app/@shared/services/aux-services/datasources.service';
import CustomStore from 'devextreme/data/custom_store';
import { LastUpdatesInterface, ProjectQueryViewResponse } from '@app/@shared/model/interface/project-query-view-response';
import { PlatformService } from '@app/auth/platform.service';
@Component({
  selector: 'app-constructive-feasibility',
  templateUrl: './constructive-feasibility.component.html',
  styleUrls: ['./constructive-feasibility.component.scss']
})
export class ConstructiveFeasibilityComponent implements OnInit {

  notificationBellIcon = '../../../assets/icons/notification-bell-svgrepo-com.png';
  fileUploaderPopupVisible: boolean = false;
  commentSectionPopupVisible: boolean = false;
  path = '/constructive-feasibility-map';
  project: string = 'Nuevo Proyecto';
  selectedProjectType: string = 'Factibilidad';
  projectName: string = '';
  projectType: string;
  openNewTab = true;
  selectedCountry: string;
  selectedProvince: string;
  selectedProject: ProjectQueryViewResponse = null;
  currentUserID: number | null = null;
  file: File | null = null;
  geoJSONFile: File;
  newProjectName: string;
  element_datasource: CustomStore;
  newProjectOption: string = 'newProject';
  existingProjectOption: string = 'existingProject';
  lastUpdatesShapes: LastUpdatesInterface[] = [];
  data = [
    {
      proyectID: '50001',
      name: 'AR-COR-VILLA-MARIA',
      creation_Date: new Date('2024-01-10').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      userID: 'EXA36482',
      user_Link: new Date('2024-01-12').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      usuarioFactibilidad: 'Pablo Jose',
      close_Date: new Date('2024-01-15').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      version: '1',
      entityShape: 'Cao',
      link_Date: new Date('2024-01-10').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})
    },
    {
      proyectID: '50002',
      name: 'AR-COR-VILLA-MARIA',
      creation_Date: new Date('2024-01-11').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      userID: 'EXA36322',
      user_Link: new Date('2024-01-13').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      usuarioFactibilidad: 'Juan Carlos',
      close_Date: new Date('2024-01-13').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      version: '2',
      entityShape: 'Cao',
      link_Date: new Date('2024-01-13').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})
    },
    {
      proyectID: '50003',
      name: 'AR-SFE-ROSARIO',
      creation_Date: new Date('2024-01-12').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      userID: 'EXA88822',
      user_Link: new Date('2024-01-14').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      usuarioFactibilidad: 'Juan Perez',
      close_Date: new Date('2024-01-14').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      version: '1',
      entityShape: 'Factibilidad',
      link_Date: new Date('2024-01-14').toLocaleDateString('es-ES', {day: '2-digit', month: '2-digit', year: 'numeric'})
    }
  ];

  countries = [
    { id: '1', name: 'AR' },
    { id: '2', name: 'BR' },
    { id: '3', name: 'PY' },
    { id: '3', name: 'UY' }
  ];

  provinces = [
    { id: '1', name: 'AMA' },
    { id: '2', name: 'AMB' },
    { id: '3', name: 'APN' },
    { id: '4', name: 'APY' },
    { id: '5', name: 'ASU' },
    { id: '6', name: 'BOQ' },
    { id: '7', name: 'BUE' },
    { id: '8', name: 'CAN' },
    { id: '9', name: 'CAP' },
    { id: '10', name: 'CAT' },
    { id: '11', name: 'CBA' },
    { id: '12', name: 'CEN' },
    { id: '13', name: 'CGZ' },
    { id: '14', name: 'CHA' },
    { id: '15', name: 'CHU' },
    { id: '16', name: 'CNL' },
    { id: '17', name: 'CON' },
    { id: '18', name: 'COR' },
    { id: '19', name: 'CZP' },
    { id: '20', name: 'DUR' },
    { id: '21', name: 'ERI' },
    { id: '22', name: 'FLR' },
    { id: '23', name: 'FOR' },
    { id: '24', name: 'GUA' },
    { id: '25', name: 'HAY' },
    { id: '26', name: 'ITA' },
    { id: '27', name: 'JUJ' },
    { id: '28', name: 'LPA' },
    { id: '29', name: 'LRI' },
    { id: '30', name: 'LVL' },
    { id: '31', name: 'MEN' },
    { id: '32', name: 'MIS' },
    { id: '33', name: 'NEU' },
    { id: '34', name: 'PGU' },
    { id: '35', name: 'RGD' },
    { id: '36', name: 'RNE' },
    { id: '37', name: 'SAL' },
    { id: '38', name: 'SCR' },
    { id: '39', name: 'SES' },
    { id: '40', name: 'SFE' },
    { id: '41', name: 'SJO' },
    { id: '42', name: 'SJU' },
    { id: '43', name: 'SLU' },
    { id: '44', name: 'SOR' },
    { id: '45', name: 'SPE' },
    { id: '46', name: 'TFU' },
    { id: '47', name: 'TUC' },
    { id: '48', name: 'TYT' }
  ];

  projectOptions = [
    {
      id: this.newProjectOption,
      title: this.translateService.instant('constructive-feasibility-new-project.title')
    },
    {
      id: this.existingProjectOption,
      title: this.translateService.instant('constructive-feasibility-existing-project.title')
    },
  ];

  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;


  constructor(
    private appRoutingService: AppRoutingService,
    private shapeService: ShapeService,
    private translateService: TranslateService,
    private credentialsService: CredentialsService,
    private dataSourceService: DataSourceService,
    private platformService: PlatformService) { }

  async ngOnInit(): Promise<void> {
    this.project = this.projectOptions[0].id;
    this.element_datasource = this.dataSourceService.projectsDatasource;
    await this.getCurrentUserID();
    await this.getLastUpdate();
  }

  openFileUploaderPopup() {
    this.fileUploaderPopupVisible = !this.fileUploaderPopupVisible;
  }

  openCommentSectionPopup() {
    this.commentSectionPopupVisible = !this.commentSectionPopupVisible;
  }

  navigateTo(data?: any) {
    let url;
    const shapeID = data?.key?.shapeID;
    url = this.appRoutingService.generateUrl(this.path);
    if (shapeID) {
      url = url + `/${shapeID}`
    }
    this.appRoutingService.navigateTo(url, this.openNewTab);
  }

  onFileSelected(event: any) {
    const file = event.value[0];
    if (file) {
      const fileName = file.name;
      const isValid = this.validateFileExtension(fileName);

      if (isValid) {
        this.file = file;
      } else {
        this.resetForm();
        alert('The file is not valid due to double extension.');
      }
    }
  }

  validateFileExtension(fileName: string): boolean {
    const doubleExtensionPattern = /(\.[a-zA-Z0-9]+)\.[a-zA-Z0-9]+$/;
    return !doubleExtensionPattern.test(fileName);
  }

  resetForm() {
    this.file = null;
    this.fileUploader.instance.reset();
  }

  uploadFile() {
    // this.navigateTo();
    const zip = this.file;
    this.unzipZip(zip);
  }

  async unzipZip(file: File) {
    try {
      const zipContent = await this.loadZipContent(file);
      const foundFolders = this.extractSubfoldersFromZip(zipContent);

      // Validate required folders and files
      this.validateRequiredFolders(foundFolders);
      this.validateRequiredFiles(zipContent, foundFolders);

      // Get all files for all folders
      const allFiles = await Promise.all(
        Array.from(foundFolders).map(folder => this.getFilesInFolder(zipContent, folder))
      );

      // Flatten files and generate GeoJSON
      const flattenedFiles = allFiles.flat();
      await this.UploadShapeFiles(flattenedFiles);

    } catch (error) {
      console.error('Error processing the ZIP file:', error);
    }
  }

  private async loadZipContent(file: File): Promise<JSZip> {
    const zip = new JSZip();
    try {
      const fileData = await file.arrayBuffer();
      return await zip.loadAsync(fileData);
    } catch (error) {
      console.error('Error loading and decompressing the ZIP file:', error);
      throw error;
    }
  }

  private extractSubfoldersFromZip(zipContent: JSZip): Set<string> {
    const subfolders: Set<string> = new Set();

    try {
      Object.keys(zipContent.files).forEach(fileName => {
        const file = zipContent.files[fileName];

        if (file.dir) {
          const folderPath = fileName.endsWith('/') ? fileName.slice(0, -1) : fileName;
          const parentFolder = folderPath.includes('/') ? folderPath.split('/').slice(0, -1).join('/') : null;

          if (parentFolder) {
            subfolders.add(folderPath);
          }
        }
      });

      console.log('Subfolders found in the ZIP file:', Array.from(subfolders));
    } catch (error) {
      this.resetForm();
      const errorMessage = `Error extracting subfolders from the ZIP file:', ${error}`
      notify(errorMessage, 'error', 4500);
      console.error('Error extracting subfolders from the ZIP file:', error);
      this.resetForm();
    }

    return subfolders;
  }

  private validateRequiredFolders(foundFolders: Set<string>): void {
    try {
      const requiredFolders = [
        Cons._INFRASTRUCTURE,
        Cons._DEVICE,
        Cons._CONTAINER,
        Cons._TRACE
      ];

      // Validate if the required folders are present regardless of the prefix
      const matchedFolders = requiredFolders.filter(requiredFolder =>
        Array.from(foundFolders).some(foundFolder => foundFolder.endsWith(requiredFolder))
      );

      if (matchedFolders.length > 0) {
        console.log('Required folders found:', matchedFolders);
      } else {
        const errorMessage = this.translateService.instant('No valid subfolders found');
        notify(errorMessage, 'error', 4500);
        this.resetForm();
      }
    } catch (error) {
      console.error('Error validating required folders:', error);
    }
  }



  private validateRequiredFiles(zipContent: JSZip, foundFolders: Set<string>) {
    const requiredExtensions = ['.dbf', '.shp', '.shx'];

    foundFolders.forEach(folder => {
      const filesInFolder = Object.keys(zipContent.files).filter(fileName => fileName.startsWith(folder));

      const missingFiles: string[] = [];
      requiredExtensions.forEach(ext => {
        const fileExists = filesInFolder.some(fileName => fileName.endsWith(ext));
        if (!fileExists) {
          missingFiles.push(ext);
        }
      });

      if (missingFiles.length > 0) {
        const errorMessage = `Missing files in folder '${folder}': ${missingFiles.join(', ')}`;
        notify(errorMessage, 'error', 4500);
        this.resetForm();
        console.error(errorMessage);
        throw new Error(errorMessage);
      }
    });
  }

  private async getFilesInFolder(zipContent: JSZip, folder: string): Promise<File[]> {
    const filesInFolder: File[] = [];

    // Wait for all promises to resolve
    const promises = Object.keys(zipContent.files).map(async (fileName) => {
      if (fileName.startsWith(folder) && fileName.includes('.') && !fileName.endsWith('.')) {
        // Only process files with an extension
        const file = zipContent.files[fileName];
        const fileBlob = await file.async('blob');

        // Extract just the file name (remove the folder path)
        const baseName = fileName.substring(fileName.lastIndexOf('/') + 1);

        // Create the File object with the extracted baseName
        const fileObj = new File([fileBlob], baseName);
        filesInFolder.push(fileObj);
      }
    });

    // Wait for all files to be processed before returning the result
    await Promise.all(promises);

    return filesInFolder;
  }



  async UploadShapeFiles(files: File[]) {
    const userID = this.currentUserID;
    const projectName = this.newProjectName;
    const projectType = this.selectedProjectType.toUpperCase();
    const selectedProjectID = this.selectedProject?.projectID;


    if (this.project == this.newProjectOption) {
      try {
        const response = await firstValueFrom(
          this.shapeService.createNewProject(files, userID, projectName, projectType)
        );
        console.log('Files uploaded successfully');
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    } else if (this.project == this.existingProjectOption) {
      try {
        const response = await firstValueFrom(
          this.shapeService.updateExistingProject(files, userID, selectedProjectID, projectType)
        );
        console.log('Files uploaded successfully');
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }

  }


  getPopupTitle(): string {
    const country = this.countries.find(c => c.id === this.selectedCountry);
    const province = this.provinces.find(p => p.id === this.selectedProvince);
    const projectName = this.projectName || '';

    this.newProjectName = `${country?.name || ''}-${province?.name || ''}-${projectName}`;

    return this.newProjectName;
  }

  async getCurrentUserID() {
    const currentUserIDstring = this.credentialsService.decodeToken()?.['userID'];
    this.currentUserID = currentUserIDstring ? parseInt(currentUserIDstring, 10) : undefined;
    return this.currentUserID;
  }

  async getLastUpdate(): Promise<void> {
    const platformID = this.platformService.getActivePlatform();
    const response = await firstValueFrom(this.shapeService.getLastUpdates())
    this.lastUpdatesShapes = response.responseData;
  }

}