<dx-drawer #drawer [openedStateMode]="'shrink'" [position]="'left'" [revealMode]="'slide'" template="template"
  [(opened)]="isSearchOpen" [height]="'100%'" [closeOnOutsideClick]="false">
  <div *dxTemplate="let data of 'template'" class="search-panel" style="width: 520px"
    [style.overflow]="showOverflow ? 'auto' : 'hidden'">
    <div style="padding: 20px;">
      <search-panel #searchPanelRef [mapViewer]="this" [erase]="erase" [entity]="selectedEntity"
        (onSearching)="search($event)" (connectionSelected)="onConnectionSelected($event)"
        (textBoxFocusIn)="handleTextBoxFocusIn($event)" (textBoxFocusOut)="handleTextBoxFocusOut($event)"
        (areaExtentCalculated)="handleQuery($event)" (switchChanged)="onSwitchValueChanged($event)"
        (selectedServiceToShowInMap)="handleSelectedService($event)" [textBoxValue]="textBoxValue"
        [dataFromMapViewer]="dataToPass" (serviceFilterOutput)="serviceFilter($event)"
        (selectedPolygonDataOutput)="searchElementsInsidePolygon($event)">
        ></search-panel>
    </div>
  </div>
  <div class="drawer-collapser" (click)="toggleDrawer()">
    <i *ngIf="!isSearchOpen" class="fas fa-chevron-right"></i>
    <i *ngIf="isSearchOpen" class="fas fa-chevron-left"></i>
  </div>
  <div class="chip-list-container" *ngIf="servicesSearchByUser?.length">
    <app-service-chip-list [services]="servicesSearchByUser" [selectedService]="selectedService"
      (toggleVisibility)="toggleServiceVisibility($event, servicesSearchByUser)"
      (remove)="removeService($event, servicesSearchByUser)" (center)="centerService($event)"></app-service-chip-list>
  </div>
  <ol-map id="mapContainer" #olMap [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [points]="points"
    [linestring]="lineString" [cssClass]="'position-fixed full-width'" [height]="'100%'" [centerViewOnFeatures]="false"
    [enableDrawing]="searchPolygonInProgress || searchSharedViewInProgress" (onMapClicked)="mapClicked($event)"
    (onElementHovered)="elementHovered($event)" (onMapZoomed)="mapZoomed($event)"
    (selectDataMapDrawing)="selectDataMapDrawing($event)" (onMapReady)="onMapReady($event)" [xygoMapSelected]="xygoMapSelected"
    [googleMapSelected]="googleMapSelected" [openStreetMapSelected]="openStreetMapSelected"
    [polygonDataSource]="polygonDataSource" [enableClusteredFeatures]="enableClusteredFeatures">
  </ol-map>
  <div *ngIf="searchAreaInProgress" class="corners c01"></div>
  <div *ngIf="searchAreaInProgress" class="corners c02"></div>
  <div *ngIf="searchAreaInProgress" class="corners c03"></div>

  <div class="top-buttons" fxLayout="row" fxLayoutAlign="center center">
    <dx-button class="top-cancel-button" *ngIf="showCancelSearchButton" (onClick)="handleCancelSearch()"
      (onClick)="handleCancelsharedView()">
      {{ 'forms.map-viewer.cancel-search' | translate }}
    </dx-button>
    <dx-button class="top-search-button" *ngIf="dataMapDrawing && dataMapDrawing.length != 0"
      (onClick)="handleQuery(this.dataMapDrawing, false, true)">
      {{ 'forms.map-viewer.search-polygon' | translate }}
    </dx-button>
    <dx-button class="top-search-button" *ngIf="searchAreaInProgress"
      (onClick)="handleQuery(this.mapBoundaries, true, false)">{{
      'forms.map-viewer.search-area' | translate }}</dx-button>
  </div>

  <div class="layers-dx-field-value-map">
    <dx-button id="search-area-button" class="view main-background-theme map-buttons-top" icon="fas fa-crop-alt"
      [disabled]="searchPolygonInProgress || dowloadImg || layersActivated" (onClick)="searchByArea()"
      [class.active]="searchAreaInProgress" [ngClass]="{ 'main-background-theme': searchAreaInProgress }"
      [ngbTooltip]="'forms.map-viewer.area' | translate ">
    </dx-button>

    <dx-button id="polygon-draw-button" class="fence main-background-theme map-buttons-top" icon="edit"
      [disabled]="searchAreaInProgress || dowloadImg || layersActivated  || sharedView" (onClick)="searchByPolygon()"
      [class.active]="searchPolygonInProgress" [ngClass]="{ 'main-background-theme': searchPolygonInProgress }"
      [ngbTooltip]="'forms.map-viewer.polygon' | translate ">
    </dx-button>

    <dx-button id="sharedView" class="main-background-theme map-buttons-top" [icon]="sharedViewIcon"
      [disabled]="dowloadImg || searchAreaInProgress || searchPolygonInProgress || layersActivated || mapButtonClicked || isPopoverOfSharedViewVisible"
      (onClick)="sharedViewSelected()" [class.active]="sharedView" [ngClass]="{ 'main-background-theme': sharedView }"
      [ngbTooltip]="'forms.map-viewer.sharedView' | translate ">
    </dx-button>

    <dx-button id="layer" class="view main-background-theme map-buttons-top" icon="fas fa-layer-group"
      [disabled]="dowloadImg || searchAreaInProgress || searchPolygonInProgress || mapButtonClicked || sharedView"
      (onClick)="selectLayers()" [class.active]="layersActivated"
      [ngClass]="{ 'main-background-theme': layersActivated }" [ngbTooltip]="'forms.map-viewer.layers' | translate ">
    </dx-button>

    <!----------------------------------------------------------POPOVERS------------------------------------------------------->
    <dx-popover target="#layer" position="bottom" [width]="'fit-content'" [(visible)]="layersActivated">
      <div *dxTemplate="let data = model; of: 'content'" id="lang_popover">
        <!-- Contenedor con overflow -->
        <div style=" overflow-y: auto; overflow-x: hidden;">
          <div *ngFor="let item of ds_layers" style="margin-bottom: 4px">
            <h5 style="font-size:1.2em;">{{ item.name }}</h5>

            <!-- Subcheckboxes -->
            <div>
              <div *ngFor="let subItem of item.subItems" style="margin-left: 10px; margin-top: 1px">
                <mat-checkbox [(ngModel)]="subItem.selected">
                  {{ subItem.name | translate }}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <h5 style="font-size:1.2em;">{{ 'forms.map-viewer.province-polygons' | translate }}</h5>
          <div *ngFor="let option of entityProvinceOption" class="parent-option-province-polygon polygon-checkbox">
            <mat-checkbox [(ngModel)]="option.selected" (change)="toggleAllSubOptions(option)">
              {{ option.name }}
            </mat-checkbox>

            <!-- SubOptions (Provincias) -->
            <div *ngIf="option.subOptions.length > 0" class="child-options ml-3">
              <div *ngFor="let subOption of option.subOptions" class="child-option">
                <mat-checkbox [(ngModel)]="subOption.selected" (change)="updateParentCheckbox(option)">
                  {{ subOption.name }}
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="feeder-polygons-title">
            <h5 style="font-size:1.2em;">{{ 'forms.map-viewer.feeder-polygons' | translate }}</h5>
          </div>
          <div *ngFor="let option of entityFeederPolygons" class="parent-option-feeder-polygon polygon-checkbox">
            <mat-checkbox [(ngModel)]="option.selected" (change)="toggleAllSubOptions(option)">
              {{ option.name }}
            </mat-checkbox>

            <!-- SubOptions (Provincias) -->
            <div *ngIf="option.subOptions.length > 0" class="child-options ml-3">
              <div *ngFor="let subOption of option.subOptions" class="child-option">
                <mat-checkbox [(ngModel)]="subOption.selected" (change)="updateParentCheckbox(option)">
                  {{ subOption.name }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <!-- Botón Confirmar -->
        <div class="text-center mt-2">
          <dx-button text="{{ 'general.confirm' | translate }}" type="default" class="confirm-button"
            (click)="confirmLayerSelection()">
          </dx-button>
        </div>
      </div>
    </dx-popover>

    <dx-popover target="#search-area-button" position="bottom" [width]="200" [(visible)]="searchAreaClicked">
      <div *dxTemplate="let data = model; of: 'content'" id="map_popover">
        <h5 style="font-size:1.2em;">{{'comp.search-panel.network-type' | translate}}</h5>
        <dx-radio-group [(value)]="selectedSearchAreaOption"
          (valueChange)="selectSearchAreaOption($event, selectedSearchAreaOption)" [items]="polenoFilterOptions"
          displayExpr="name" valueExpr="name" class="mb-2 custom-radio-group">
        </dx-radio-group>
      </div>
    </dx-popover>

    <dx-popover target="#polygon-draw-button" position="bottom" [width]="200" [(visible)]="polygonDrawClicked">
      <div *dxTemplate="let data = model; of: 'content'" id="map_popover">
        <h5 style="font-size:1.2em;">{{'comp.search-panel.network-type' | translate}}</h5>
        <dx-radio-group [(value)]="selectedPolygonDrawOption"
          (valueChange)="selectPolygonDrawOption($event, selectedPolygonDrawOption)" [items]="polenoFilterOptions"
          displayExpr="name" valueExpr="name" class="mb-2 custom-radio-group">
        </dx-radio-group>
      </div>
    </dx-popover>

    <dx-popover target="#mapLayer" position="bottom" [width]="200" [(visible)]="mapButtonClicked">
      <div *dxTemplate="let data = model; of: 'content'" id="map_popover">
        <h5 style="font-size:1.2em;">{{'forms.map-viewer.maps' | translate}}</h5>
        <dx-radio-group [(value)]="selectedMapOption" (valueChange)="selectMapOption($event, selectedMapOption)"
          [items]="mapOptions" displayExpr="name" valueExpr="name" class="mb-2 custom-radio-group">
        </dx-radio-group>
      </div>
    </dx-popover>

    <dx-popover target="#sharedView" position="bottom" [width]="200" [(visible)]="sharedView"
      (visibleChange)="isPopoverOfSharedViewVisible = $event">
      <div *dxTemplate="let data = model; of: 'content'" id="map_popover">
        <h5 style="font-size:1.2em;">{{ 'forms.map-viewer.sharedView' | translate }}</h5>

        <div *ngFor="let option of entityOption" class="parent-option">
          <mat-checkbox [(ngModel)]="option.selected" (change)="toggleAllSubOptions(option)" class="parent-checkbox">
            {{ option.name }}
          </mat-checkbox>

          <div *ngIf="option.subOptions.length > 0" class="child-options ml-3">
            <div *ngFor="let subOption of option.subOptions" class="child-option">
              <mat-checkbox [(ngModel)]="subOption.selected" (change)="updateParentCheckbox(option)"
                class="child-checkbox">
                {{ subOption.name }}
              </mat-checkbox>
            </div>
          </div>
        </div>

        <div class="text-right mt-2">
          <dx-button text="{{ 'general.confirm' | translate }}" type="default" class="confirm-button"
            (click)="confirmSelection()">
          </dx-button>
        </div>
      </div>
    </dx-popover>

    <!----------------------------------------------------------END OF POPOVERS------------------------------------------------------->


  </div>


  <div class="dx-field-value-map">
    <dx-button id="clear-searches-performed" icon="fas fa-eraser" class="main-background-theme"
      (onClick)="clearSearchesPerformed()" [ngClass]="{ 'main-background-theme': searchAreaInProgress }"
      [ngbTooltip]="'forms.map-viewer.eraser' | translate "
      [disabled]="searchAreaInProgress || searchPolygonInProgress || layersActivated || mapButtonClicked || isPopoverOfSharedViewVisible">
    </dx-button>

    <div class="container-search-polygon">

      <dx-button id="mapLayer" class="main-background-theme" icon="fa fa-map"
        [disabled]="dowloadImg || searchAreaInProgress || searchPolygonInProgress || layersActivated || sharedView"
        (onClick)="selectMap()" [class.active]="mapButtonClicked"
        [ngClass]="{ 'main-background-theme': mapButtonClicked }" [ngbTooltip]="'forms.map-viewer.maps' | translate ">
      </dx-button>
      <dx-button id="download-map" class="fence main-background-theme" icon="fas fa-download"
        [disabled]="layersActivated || searchAreaInProgress || searchPolygonInProgress || mapButtonClicked || sharedView"
        (click)="downloadMap()" [class.active]="dowloadImg" [ngClass]="{ 'main-background-theme': dowloadImg }"
        [ngbTooltip]="'forms.map-viewer.dowload' | translate ">
      </dx-button>
    </div>


  </div>
</dx-drawer>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: 'dx-drawer' }" [(visible)]="loading"
  [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<dx-popup [(visible)]="urlPopupVisible" [width]="400" [height]="200" [dragEnabled]="false" [closeOnOutsideClick]="true">
  <div *dxTemplate="let data of 'content'">
    <h5>{{'general.generated-url' | translate}}</h5>
    <input type="text" [value]="generatedURL" readonly style="width: 100%;" />
    <div class="text-right mt-2">
      <dx-button text="{{'general.copy-to-clipboard' | translate}}" type="default" class="copy-to-clipboard-button"
        (click)="copyToClipboard()">
      </dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup [(visible)]="showMaxServiceReachedModal" [dragEnabled]="false" [closeOnOutsideClick]="true" [showTitle]="true"
  title="Límite Alcanzado" [width]="300" [height]="200">

  <div>
    <p>Se alcanzó el límite de servicios para mostrar. Elimina uno antes de agregar otro.</p>
  </div>

  <div class="popup-buttons">
    <dx-button text="Aceptar" (click)="showMaxServiceReachedModal = false"></dx-button>
  </div>
</dx-popup>


<dx-popup [(visible)]="showLayersPopupAlert" [dragEnabled]="false" [closeOnOutsideClick]="true" [showTitle]="true"
  [title]="'general.action-denied' | translate" [width]="300" [height]="200">

  <div>
    <p>{{ 'general.select-one-element-type' | translate }}</p>
  </div>

  <div class="popup-buttons">
    <dx-button text="{{ 'general.accept' | translate }}" (click)="showLayersPopupAlert = false"></dx-button>
  </div>
</dx-popup>